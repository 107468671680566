import React, { useRef } from "react";
import { EVENT } from "../constants/events.constants";
import { IMAGE_PREFIX } from "../constants/urls.constants";
import { track } from "../utils/analytics";
import { getNewSignInCommonEventProps } from "../utils/helpers";

function AmcCreateAccount({ stepData, workflowSubmitCb }: any) {
  const isInitialMount = useRef(true);

  if (isInitialMount.current) {
    isInitialMount.current = false;
    track(EVENT.dfc_auth_error_page_loaded, {
      ...getNewSignInCommonEventProps(),
      ...stepData
    });
  }

  function stepToCapureEmail() {
    workflowSubmitCb({
      ...stepData,
      next_screen: {
        identifier: "capture_name_email",
        capture_name_email_amc: {
          create_account: stepData.details.create_account,
          validation: {
            email: {min_length: 1, max_length: 50, regex: "[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}"}
        }
      }
    }});
  }

  return (
    <div>
      <p className="text-main m-b-0" data-testid='title'>{stepData.title}</p>
      {stepData.subtitle && <p className="text-sm mt-2 mb-6 txt-type-para" data-testid='subtitle'>{stepData.subtitle}</p>}
      <div className='flex'>
        <a
          onClick={stepToCapureEmail}
          href={stepData.details.create_account.web_detail.url}
          target="_blank"
          data-testid='button-title'
          className="sign-apply-btn text-center items-center py-3 px-6 rounded-full justify-center block"
        >
          Create AMC Stubs Account
        </a>
      </div>
      <div className='txt-type-para mt-2 text-xs'>
        AMC Stubs Insider is free to join
      </div>
    </div>
  );
}

export default AmcCreateAccount;
