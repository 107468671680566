import React, { useEffect, useState } from "react";
import emitter from '../utils/emitter';
import Spinner from "../components/Spinner";
import { track } from "../utils/analytics";
import { useHistory } from 'react-router';
import { IMAGE_PREFIX, REAPPLY_APPLICATION_API_URL } from "../constants/urls.constants";
import { postWithAuthHeader } from "../utils/client";
import {EVENT} from "../constants/events.constants";

type Props = {
  setWorkflow: Function,
  workflow: Object
};

function CardDeclined(props: Props) {
  const template = props.workflow["steps"][0]["ui_template_type"]
  const step = props.workflow["steps"][0][template];
  const imgSrc = `${IMAGE_PREFIX}/${step.image_name}.png`;
  const breadcrumbs = props.workflow['breadcrumbs'];
  const [submitting, setSubmitting] = useState(false);
  const identifier = props.workflow["steps"][0].identifier;
  const showNextSteps = false;
  const history = useHistory();

  useEffect(() => {
    emitter.emit('progress', { breadcrumbs, identifier, showNextSteps });
  }, [breadcrumbs, identifier, showNextSteps]);

  track('onb_application_rejected')

  function onBtnClick(e) {
    e.preventDefault();
    setSubmitting(true);
    postWithAuthHeader(REAPPLY_APPLICATION_API_URL, {}, EVENT.onb_repapply_application_failed)
      .then((response: any) => {
        setSubmitting(false);
        props.setWorkflow(response);
        history.push('/apply-card');
      })
      .catch(handleLoader);
  }

  function handleLoader() {
    setSubmitting(false);
  }

  return (
    <div className="flex flex-col h-full">
      <div className="relative xl:top-10 x-sm:top-4 2xl:top-14 2xl:mb-4 top-14 mb-4 x-sm:mb-1 xl:mb-0">
        <p className="text-main x-sm:flex x-sm:text-center" data-testid='title'>
          {step.default_state.title}
        </p>
      </div>
      <img className="w-3/4 xl:mt-8 2xl:mt-24 mt-24 mx-auto" src={imgSrc} alt="not found" />
      <div className="flex justify-center">
        <p className="mt-6 xl:mt-4 xl:mb-8 2xl:mt-6 2xl:mb-10 mb-10 text-lg">{step.description}</p>
      </div>
      {submitting && step.default_state.first_action_title && <button data-testid='button_title' className="bg-theme text-lg font-bold rounded-full x-sm:py-2 x-sm:px-20 2xl:py-5 2xl:px-28 py-5 px-28 text-white block mx-auto focus:outline-none">
        <div className="2xl:mt-1 2xl:mx-6"><Spinner /></div>
      </button>}
      {!submitting && step.default_state.first_action_title && <button data-testid='button_title' onClick={onBtnClick} className="bg-theme text-lg font-bold rounded-full x-sm:py-2 x-sm:px-20 2xl:py-5 2xl:px-28 py-5 px-28 text-white block mx-auto focus:outline-none">
        {step.default_state.first_action_title}
      </button>}
    </div>
  );
}

export default CardDeclined;
