import React, { useEffect, useState, useCallback, useRef } from "react";
import { get, postWithAuthHeader } from "../utils/client";
import {
  APPLICATION_BASE_URL,
  DASHBOARD_API_URL,
  GET_PAYMENT_METHOD,
  SET_PAYMENT_METHOD,
  GET_LINK_TOKEN,
  IMAGE_PREFIX,
  PAYMENT_API,
  GET_AUTOPAY_STATUS,
  IMAGE_BASE_URL,
} from "../constants/urls.constants";
import moment from "moment";
import LoadingIcon from "../components/Loading";
import { numberFormatter } from "../utils/numberFormatter";
import ReactHtmlParser from "html-react-parser";
// import { PlaidLink } from "react-plaid-link";
import ClientStorage from "../utils/client-storage";
import BtnNext from "../components/BtnNext";
import { track } from "../utils/analytics";
import { EVENT } from "../constants/events.constants";
import LinkBankAccountButton from "./LinkBankAccountButton";
import PaymentSucess from "./Payment_Success";
import PaymentError from "./Payment_Error";
import { DASHBOARD_PATH } from "../constants/route-paths.constants";
import BtnSpinnerNext from "../components/BtnSpinnerNext";
import { ArrowDown } from "../components/ArrowDown";
import MigrationInProgress from "./MigrationInProgress";
import { logErrorToSentry } from "../utils/helpers";
type Props = { partnerName: String };

const CUSTOM_AMOUNT_MIN_VALUE = 0.01;

function Payment(props: Props) {
  const [data, setData] = useState(null);
  const [HasError, setHasError] = useState(false);
  const [Loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [learnMore, setLearnMore] = useState(false);
  const [showPaymentSuccess, setShowPaymentSuccess] = useState(false);
  const [showPaymentError, setShowPaymentError] = useState(false);
  const [linkToken, setLinkToken] = useState<string | null>(null);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [customAmt, setCustomAmt] = useState("");
  const [showPaymentMethod, setShowPaymentMethod] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [paymentSuccessData, setPaymentSuccessData] = useState(null);
  const [autoPayResData, setAutoPayResData] = useState(null);
  const [paymentBtnText, setPaymentBtnText] = useState("Pay Minimum Due");
  const [customAmtErr, setCustomAmtErr] = useState(false);
  const [isMakingPayment, setIsMakingPayment] = useState(false);
  const [PaymentDisclaimer, setPaymentDisclaimer] = useState(null);
  const cardCSS = `w-full paymentCard h-48 cursor-pointer x-sm:py-8 x-sm:mr-0 x-sm:mb-4 md:mb-4 py-9 pl-5 pr-7 hover:bg-white hover:text-black rounded-3xl mr-4 dashboard-card-${props.partnerName}`;
  const cardCSSSelect = `activeCard`;
  const [showMigrationError, setShowMigrationError] = useState(false);

  const [selected, setSelected] = useState("MINIMUM-PAYMENT");
  const listRef = useRef(null);
  const logo_image = props.partnerName === 'krowdfit' || props.partnerName === 'amc' ? `${IMAGE_PREFIX}/white_logo.png` : `${IMAGE_PREFIX}/logo.png`;
  const arrowColor = document.body.style.getPropertyValue("--theme-card-text");

  React.useEffect(() => {
    track(EVENT.payBankGetTokenApi);
    async function createLinkToken() {
      postWithAuthHeader(
        GET_LINK_TOKEN,
        {},
        EVENT.pay_bank_account_linked_token_fetch_failed
      ).then((res: any) => {
        let token = res?.data?.link_token;
        ClientStorage.setLocal("link_token", token);
        ClientStorage.setLocal("redirectUrl", window.location.href);
        setLinkToken(token);
        track(EVENT.payBankTokenApiSuccess);
      });
    }

    let metadata = ClientStorage.getLocal("plaid-metadata");

    if (metadata === "null" || metadata === null) {
      createLinkToken();
    } else {
      if (ClientStorage.getLocal("plaid-metadata")) {
        let metadata = JSON.parse(ClientStorage.getLocal("plaid-metadata"));
        let data = Object.assign({}, metadata, {
          bank_token: ClientStorage.getLocal("bank_token"),
        });
        updatePaymentMethod(data);
        createLinkToken();
      }
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    track(EVENT.payments_page_load_initiated);
    Promise.allSettled([get(DASHBOARD_API_URL), get(GET_PAYMENT_METHOD), get(GET_AUTOPAY_STATUS)])
      .then(([accountDataRes, paymentDataRes, autopayRes]) => {
        return [accountDataRes, paymentDataRes, autopayRes].map((response) => {
          return response.status === "fulfilled" ? response.value : null;
        });
      })
      .then(([accountData, paymentMethodData,autopayData]) => {
        accountData?.payment?.payment_home_card?.is_migration_in_progress
          ? setShowMigrationError(true)
          : setShowMigrationError(false)
        setData(accountData);
        setAutoPayResData(autopayData);
        track(EVENT.payments_page_loaded)
        if (paymentMethodData?.methods.length) {
          setPaymentMethods(paymentMethodData?.methods);
          setSelectedPaymentMethod(paymentMethodData.methods[0] ?? []);
        }
        if(autopayData.payment_disclaimer) {
          setPaymentDisclaimer(autopayData.payment_disclaimer);
        }
      })
      .catch((er) => {
        track(EVENT.payments_page_load_failed)
        setHasError(true);
      })
      .finally(() => setLoading(false));
  }, []);

  function onSuccess(token: any, metadata: any) {
    track(EVENT.onb_bank_connected);
    track(EVENT.addBankClicked);
    updatePaymentMethod(metadata);
  }

  const updatePaymentMethod = (data) => {
    track(EVENT.payAddBankApiRequested);
    setBtnLoading(true);

    data.hasOwnProperty('status') && delete data.status;

    postWithAuthHeader(SET_PAYMENT_METHOD, data, EVENT.payment_failed)
      .then((res: any) => {
        ClientStorage.deleteLocal("bank_token");
        ClientStorage.deleteLocal("plaid-metadata");
        ClientStorage.deleteLocal("is_plaid_callback");

        get(GET_PAYMENT_METHOD).then((res) => {
          setPaymentMethods(res.methods);
          setSelectedPaymentMethod(res?.methods?.[0]);
        });
        track(EVENT.payAddBankApiSuccess);
      })
      .catch((er) => {
        setHasError(true);
        track(EVENT.payAddBankApiFailed);
      })
      .finally(() => {
        setBtnLoading(false);
        listRef?.current?.scrollTo(0, 0);
      });
  };

  const showOptions = () => {
    setShowPaymentMethod(!showPaymentMethod);
  };

  function handleChange(evt) {
    setCustomAmt(evt.target.validity.valid ? evt.target.value : customAmt);
    Number(evt.target.value) <= CUSTOM_AMOUNT_MIN_VALUE || evt.target.value === ""
      ? setCustomAmtErr(true)
      : setCustomAmtErr(false);
  }

  if (HasError) {
    return (
      <div className="dashboard-error text-2xl text-center pt-14">
        Some error occured fetching details. Please try again.
      </div>
    );
  }

  if (Loading) {
    return <LoadingIcon className="mx-auto" />;
  }

  const updateCard = (key) => {
    setSelected(key);
    const text = cardsMap.find((f) => f.key === key).title;
    setPaymentBtnText(text);
  };

  const makePayment = () => {
    track(EVENT.payApiRequested);
    const amount = cardsMap.find((f) => f.title === paymentBtnText).amount;
    const data = {
      frequency: "INSTANT",
      type: "FIXED-AMOUNT",
      method_id: selectedPaymentMethod.id,
      amount,
    };

    setIsMakingPayment(true);
    postWithAuthHeader(PAYMENT_API, data, EVENT.one_time_payment_posting_failed)
      .then((res: any) => {
        setPaymentSuccessData(res.data);
        if (res.data.id) {
          setShowPaymentError(false);
          setShowPaymentSuccess(true);
        }
        track(EVENT.payApiSuccess);
      })
      .catch((e) => {
        console.error(e);
        setShowPaymentError(true);
        setShowPaymentSuccess(false);
        logErrorToSentry(EVENT.payApiFailed, e.message, e);
        track(EVENT.payApiFailed, {error: e.message});
      })
      .finally(() => {
        setIsMakingPayment(false);
      });
  };

  const { credit_line = {}, payment = {} } = data;
  const { legal_info = {} } = payment;
  const { short_info = {}, long_info = {} } = legal_info;
  const desc = short_info.desc || '';
  const more_info_title = short_info.more_info_title;

  const {
    statement_balance = 0,
    minimum_payment = 0,
    card_balance = 0,
  } = credit_line;

  const cardsMap = [
    {
      title: "Pay Minimum Due",
      key: "MINIMUM-PAYMENT",
      text1: "Pay Minimum",
      text2: "Due",
      amount: parseFloat(minimum_payment),
      id: "card1",
    },
    {
      title: "Pay Statement Balance",
      text1: "Pay Statement",
      text2: "Balance",
      key: "STATEMENT-BALANCE",
      amount: parseFloat(statement_balance),
      id: "card2",
    },
    {
      title: "Pay Current Balance",
      key: "CARD-BALANCE",
      text1: "Pay Current",
      text2: "Balance",
      amount: parseFloat(card_balance),
      id: "card3",
    },
    {
      title: "Pay Now",
      key: "FIXED-AMOUNT",
      text1: "Enter",
      text2: "Amount",
      amount: parseFloat(customAmt),
      id: "card4",
    },
  ];

  let longInfo = "";
  const customErr = customAmt === "" || Number(customAmt) <= 1;
  const selectedAmount = (selected !== 'FIXED-AMOUNT') && cardsMap.find(item => item.key === selected)?.amount;

  function renderPaymentDisclaimer(disclaimer_str) {
    let formatedDate = moment(new Date()).format('MMM DD, YYYY');
    let amt = selectedAmount ? `$${String(selectedAmount)}` : (String(customAmt).length > 0 ? `$${customAmt}` : "$0.00");

    return ReactHtmlParser(String(disclaimer_str).replace('${payment_type}', `"${paymentBtnText}"`)
      .replace('${amount}', amt)
      .replace('${date}', formatedDate));
  }

  let paymentButtonDisable =
    (customAmtErr && selected === "FIXED-AMOUNT") ||
    (customErr && selected === "FIXED-AMOUNT") ||
    (selected !== "FIXED-AMOUNT" && selectedAmount <= 0);

  longInfo = long_info?.html_desc;

  const renderMakePaymenButton = () => {
    if (selectedPaymentMethod) {
      return isMakingPayment ? (<BtnSpinnerNext />) : (
        <BtnNext
          text={paymentBtnText}
          className={
            paymentButtonDisable
              ? `text-black opacity-60  pay-btn-${props.partnerName} bg-white block text-center text-xl tracking-tight h-16 p-5 cursor-pointer w-72 ml-auto mr-auto mt-8 rounded-full brand-btn-solid`
              : `text-black bg-white pay-btn-${props.partnerName} block text-center text-xl tracking-tight h-16 p-5 cursor-pointer w-72 ml-auto mr-auto mt-8 rounded-full brand-btn-solid`
          }
          onClick={makePayment}
          disabled={paymentButtonDisable}
        />
      );
    } else {
      return (
        <div className="relative">
          <LinkBankAccountButton
            link_token={linkToken}
            handleSubmit={onSuccess}
            title="Add Bank"
            type="button"
          />
          {btnLoading && (
            <div className="loading-black align-spinner-big"></div>
          )}
        </div>
      )
    }
  }

  return (
    <div
      className={`dashboard-${props.partnerName} dashboard-profile-${props.partnerName} xl:h-screen lg:h-screen py-11 px-12 flex x-sm:w-full x-sm:px-8 x-sm:pt-28 sm:pt-28`}
    >
      <div data-testid="logo" className="fixed top-10">
        <img className="w-36" src={logo_image} alt="logo" />
      </div>

      {!showPaymentSuccess && !showPaymentError && <div
        onClick={() => window.location.assign(DASHBOARD_PATH)}
        title="Back to Dashboard"
        className="w-10 cursor-pointer top-16 rounded-full x-sm:right-4 fixed right-12 page-close x-sm:top-10 sm:top-10"
      >
        <img src={`${IMAGE_PREFIX}/close-page.png`} alt="Cancel" />
      </div>}
      {!showPaymentSuccess && !showPaymentError && !showMigrationError && <div className="lg:w-full xl:w-full x-sm:w-full x-sm:justify-center sm:mt-14 flex flex-col justify-between">
          <div className="h-54 flex flex-col lg:flex-row justify-center lg:justify-between">
            {cardsMap.map((card) => {
              return (
                <div
                  key={card.id}
                  data-testid={card.id}
                  className={
                    selected === card.key
                      ? `${cardCSSSelect} ${cardCSS}`
                      : cardCSS
                  }
                  onClick={() => {
                    updateCard(card.key);
                    selected.includes("FIXED-AMOUNT") && customErr
                      ? setCustomAmtErr(true)
                      : setCustomAmtErr(false);
                  }}
                >
                  <div className="flex flex-row justify-between mb-4 x-sm:mb-6 check">
                    <div>
                      <p className="text-xs text-1-heading-2 uppercase text-caption-6 text-t1-color">{card.text1}</p>
                      <p className="text-xs text-1-heading-2 uppercase text-caption-6 text-t1-color">{card.text2}</p>
                    </div>
                    <div className="roundCheckbox">
                      <input
                        type="checkbox"
                        id={card.id}
                        checked={selected === card.key}
                      />
                      <label htmlFor={card.id}></label>
                    </div>
                  </div>
                  {card.id !== "card4" ? (
                    <div>
                      <p className="text-4xl font-bold text-1-heading">
                        {numberFormatter(card.amount)}
                      </p>
                      <div className="flex my-2">
                        {(selected === card.key && card.amount <= 0) ? (
                          <span className="text-xs cardActionErr font-avenir-book">
                            {`Amount must be more than $${CUSTOM_AMOUNT_MIN_VALUE}`}
                          </span>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div>
                        <span className="font-bold text-4xl text-1-heading">$</span>
                        <input
                          className="md:w-36 w-48 bg-transparent font-bold text-4xl text-1-heading"
                          type="text"
                          pattern="^[0-9\.]*$"
                          onChange={handleChange}
                          maxLength={6}
                          value={customAmt}
                        />
                      </div>
                      <div className="flex my-2">
                        {customAmtErr && selected === "FIXED-AMOUNT" ? (
                          <span className="text-xs cardActionErr font-avenir-book">
                            {`Enter amount higher than $${CUSTOM_AMOUNT_MIN_VALUE}`}
                          </span>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        <div className="flex justify-between x-sm:flex-col x-sm:justify-center mt-8 lg:mt-20 x-sm:h-auto items-end">
          <div className="w-1/2 x-sm:w-full md:mb-14">
            <h4 className="text-xl font-normal mb-4 text-1-heading-2 text-t1-color">
              Pay full statement balance to avoid paying interest.
            </h4>
            <div className="max-h-72 x-sm:h-auto x-sm:max-h-full">
              <p className="text-xs pr-4 text-1-heading-3 txt-type-div text-t2-color">
                {learnMore ? (
                  <span>
                    {ReactHtmlParser(longInfo)}
                    <b
                      className="cursor-pointer underline"
                      onClick={() => {
                        setLearnMore(!learnMore);
                      }}
                    >
                      &nbsp;{" Show Less"}
                    </b>
                  </span>
                ) : (
                  <span>
                    {ReactHtmlParser(desc)}
                    <span>
                      <b
                        className="cursor-pointer underline"
                        onClick={() => {
                          setLearnMore(!learnMore);
                        }}
                      >
                        &nbsp; {more_info_title}
                      </b>
                    </span>
                  </span>
                )}
              </p>
            </div>
          </div>
          <div className="x-sm:my-8 mb-8">
            {selectedPaymentMethod && (
              <div className="relative">
                <div
                  className="flex mb-6 x-sm:mb-0 items-center justify-between cursor-pointer"
                  onClick={showOptions}
                >
                  <div className="flex">
                    <img
                      className="w-8 mr-2.5 rounded-lg"
                      src={selectedPaymentMethod?.thumbnail_url}
                      alt="bank_logo"
                    />
                    <div>
                      <p className="uppercase text-xxs text-1-heading-3 text-t2-color">Pay From</p>
                      <p className="text-sm capitalize text-1-heading-3 text-t2-color">
                        {selectedPaymentMethod?.bank_name}{" "}
                        {selectedPaymentMethod?.account_type}{" "}
                        {selectedPaymentMethod?.account_last_4_digits}
                      </p>
                    </div>
                  </div>
                  <div className="text-1-heading rotate-90">
                    <span><ArrowDown fillColor={arrowColor} /></span>
                  </div>
                </div>
                {PaymentDisclaimer && (
                  <p className="text-xs pr-4 text-1-heading-3 w-72 txt-type-div text-t1-color">
                    {renderPaymentDisclaimer(PaymentDisclaimer)}
                  </p>
                )}
                <div
                  className={
                    showPaymentMethod
                      ? "paymentOptions payment_active z-20"
                      : "paymentOptions"
                  }
                >
                  <ul
                    className="px-7 pt-4 max-h-72 overflow-y-auto"
                    ref={listRef}
                  >
                    {paymentMethods.map((method) => {
                      return (
                        <li
                          key={method.id}
                          className="cursor-pointer pb-4 mt-4"
                          onClick={() => {
                            method && setSelectedPaymentMethod(method);
                            setShowPaymentMethod(false);
                          }}
                        >
                          <div className="flex justify-between">
                            <div className="flex">
                              <img
                                className="w-8 mr-4 rounded-lg"
                                src={method?.thumbnail_url}
                                alt="bank_logo"
                              />
                              <div>
                                <p className="uppercase text-xxs">
                                  {method?.bank_name}
                                </p>
                                <p className="text-sm capitalize">
                                  {method?.account_type}{" "}
                                </p>
                              </div>
                            </div>
                            <div className="text-sm self-center font-bold flex items-center">
                              <p>...{method?.account_last_4_digits}</p>
                              <div className="w-4 ml-4">
                                {selectedPaymentMethod?.id === method?.id && (
                                  <img
                                    src={`${IMAGE_BASE_URL}/check.png`}
                                    alt="selected"
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                    <li className="pb-8 text-center mt-2">
                      {showPaymentMethod && (
                        <span className="flex relative">
                          <LinkBankAccountButton
                            link_token={linkToken}
                            handleSubmit={onSuccess}
                            title="Add bank"
                            type="link"
                          />
                          {btnLoading && (
                            <div className="loading-black align-spinner"></div>
                          )}
                        </span>
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            )}
            {renderMakePaymenButton()}
          </div>
        </div>
        <div className="flex justify-between x-sm:flex-col-reverse items-center">
          {props.partnerName === "deserve_edu" ? (<img className="img-powered-by" src={`${IMAGE_BASE_URL}/deserve_edu/powered_by_dark.png`} alt="" />) : <span></span>}
          {(autoPayResData && autoPayResData?.institution_unavailable_info_msg) && <p data-testid="institution_unavailable_info_msg" className="x-sm:text-center sm:text-center md:text-right lg:text-right xl:text-right pb-6 text-t2">{autoPayResData.institution_unavailable_info_msg}</p>}
        </div>
      </div>}
      {showPaymentSuccess && (
        <PaymentSucess
          meta={paymentSuccessData}
          first_name={data.first_name}
          partnerName={props.partnerName}
        />
      )}
      {showPaymentError && (
        <PaymentError
          makePayment={makePayment}
          partnerName={props.partnerName}
          type="payments"
        />
      )}
      {showMigrationError &&
        (
          <MigrationInProgress
            partnerName={props.partnerName}
          />
        )
      }
      <div
        className={showPaymentMethod ? "backdrop" : ""}
        onClick={() => {
          setShowPaymentMethod(false);
        }}
      ></div>
    </div>
  );
}

export default Payment;
