import { IMAGE_PREFIX } from "../constants/urls.constants";
import { DASHBOARD_PATH } from "../constants/route-paths.constants";
import { paymentMessage } from "../constants/payment.constants";

type Props = { partnerName: String };

function MigrationInProgress(props: Props) {
  const logo_image = props.partnerName === 'krowdfit' || props.partnerName === 'customers_bank' || props.partnerName === 'amc' || props.partnerName === 'blockfi' || props.partnerName === 'slm_ignite' ? `${IMAGE_PREFIX}/white_logo.png` : `${IMAGE_PREFIX}/logo.png`;
  return (
    <div
      className={`x-sm:min-h-full dashboard-${props.partnerName} container-dashboard pt-3 lg:pt-10 pr-7 lg:pr-12 pb-10 lg:pb-4 pl-7 lg:pl-12 fixed inset-0 z-50`}
      data-testid="migrationInProgress"
    >
      <div data-testid="logo" className="fixed">
        <img className="w-20" src={logo_image} alt="logo" />
      </div>
      <div
        onClick={() => window.location.assign(DASHBOARD_PATH)}
        title="Back to Dashboard"
        className={`w-8 cursor-pointer rounded-full x-sm:right-4 fixed right-12`}
      >
        <img src={`${IMAGE_PREFIX}/${props.partnerName === 'customers_bank' || props.partnerName === 'slm_ignite' ? 'close-page-white' : 'close-page'}.png`} alt="Cancel" />
      </div>
      <div className="x-sm:mt-36 md:mt-36 flex x-sm:flex-col md:flex-col lg:flex-row lg:items-center lg:vertical-center">
        <div className="x-sm:w-full md:w-full lg:w-2/5 mr-12 x-sm:mr-0 lg:mr-0">
          <p className="x-sm:text-3xl text-center lg:text-left text-4xl font-semibold x-sm:pb-16 md:pb-16 lg:pb-0 text-heading-2">
            {paymentMessage.migrationError.titleLine1} <br /> {paymentMessage.migrationError.titleLine2}{" "}
          </p>
        </div>
        <div className="x-sm:mx-auto md:mx-auto lg:m-0 w-64 lg:w-2/5">
          <img src={`${IMAGE_PREFIX}/error.png`} alt="success" />
        </div>
      </div>
    </div>
  );
}

export default MigrationInProgress;
