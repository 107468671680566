import React, {useEffect, useState} from "react";
import emitter from '../utils/emitter';
import {postWithAuthHeader} from "../utils/client";
import {track} from "../utils/analytics";
import {CREDIT_BUREAU_FREEZE_STATE_ENDPOINT, IMAGE_PREFIX} from "../constants/urls.constants";
import BtnSpinnerNext from "../components/BtnSpinnerNext";
import {EVENT} from "../constants/events.constants";

type Props = {
  setWorkflow: Function,
  workflow: Object
};

function FrozenCreditBureau(props: Props) {
  const [submitting, setSubmitting] = useState(false);
  const template = props.workflow["steps"][0]["ui_template_type"];
  const step = props.workflow["steps"][0][template];
  const breadcrumbs = {};
  const identifier = props.workflow["steps"][0].identifier;


  useEffect(() => {
    emitter.emit('progress', {breadcrumbs, identifier});
  }, [breadcrumbs, identifier]);

  const imageName = `${IMAGE_PREFIX}/${step.image.name}.${step.image.type}`;

  track('onb_application_frozen_bureau')

  function onBtnClick(e) {
    e.preventDefault();
    setSubmitting(true);
    postWithAuthHeader(CREDIT_BUREAU_FREEZE_STATE_ENDPOINT, {}, EVENT.onb_unlock_credit_bureau_submit_failed)
    .then((response) => {
      track(EVENT.onb_unlock_credit_bureau_submit_submitted)
      setSubmitting(false);
      props.setWorkflow(response)
    })
    .catch(ex => {
      track(EVENT.onb_unlock_credit_bureau_submit_failed)
      handleSpinner(ex)
    });
  }

  function handleSpinner(resp?: any) {
    setSubmitting(false);
  }

  return (
    <div className="content-container h-full">
      <div className="flex flex-col text-center">
        <div className="pb-2 pt-12">
          <p className="text-main mb-0" data-testid="title"> {step.default_state.title}</p>
          <p className="text-sm">{step.default_state?.subtitle}</p>
        </div>
        <div className="pt-24 pb-12 xl:pt-16 xl:pb-6">
          <img src={imageName} className="2xl:w-3/5 xl:w-2/5 mx-auto mb-7" alt=''/>
        </div>
        {step.description && <small className="theme-text-color" data-testid="description">{step.description}</small>}
        {!submitting && <button className="bg-theme rounded-full x-sm:py-3 x-sm:px-24 py-3 mt-7 px-28 mb-14 text-white block mx-auto focus:outline-none" onClick={onBtnClick}>
          <label data-testid='action-title'>{step.default_state.first_action_title}</label>
        </button>}
        {submitting && <BtnSpinnerNext />}
      </div>
    </div>
  );
}

export default FrozenCreditBureau;
