import React, { useState, useEffect } from "react";
import IconCheck from "../components/IconCheck";
import IconUnCheck from "../components/IconUnCheck";
import ResumeApplication from "./ResumeApplication";
import emitter from '../utils/emitter';
import {track} from "../utils/analytics";
import BtnSpinnerNext from "../components/BtnSpinnerNext";
import {EVENT} from "../constants/events.constants";

type Props = {
  handleSubmit: Function,
  currentStep: any,
  workflow: Object
};

function Agreement(props: Props) {
  const template = props.workflow["steps"][0]["ui_template_type"];
  const step = props.workflow["steps"][0][template];
  const [userConsent, setUserConsent] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [saveAndContinue, setSaveAndContinue] = useState(false);
  const breadcrumbs = props.workflow['breadcrumbs'];
  const identifier = props.workflow["steps"][0].identifier;

  useEffect(() => {
    emitter.emit('progress', { breadcrumbs, identifier });
  }, [breadcrumbs, identifier, props.workflow]);

  const handleSubmit = (e) => {
    let data = {"accepted_pre_card_holder_agreement": userConsent}

    if(userConsent) {
      track(EVENT.onb_TNC_accepted);
      track(EVENT.onb_submit_application_clicked);
      setSubmitting(true);
      props.handleSubmit(data)
      .catch(handleSpinner);
    }

    e.preventDefault()
  }

  const handleSaveAndContinueApplication = () => {
    track(EVENT.onb_TNC_saved);
    setSaveAndContinue(true)
  }

  function handleSpinner(resp?: any) {
    setSubmitting(false);
  }

  if(!saveAndContinue) {
    return (
      <div>
        <p className="text-main md:mb-2 sm:text-main x-sm:mt-10 x-sm:mb-10">
          {step.default_state.title}
        </p>
        <p className="mb-11 theme-text2-color"><small>{step?.description}</small></p>
        <a className="block mb-10 underline font-bold agreement-title" rel="noreferrer" target="_blank" href={step.agreement_url}>{step.agreement_action_title}</a>

        <div className="flex flex-row mb-10 w-full space-x-4">
          <span>
            <input
              type="checkbox"
              name="user-consent"
              id="user-consent-checkbox"
              className="invisible absolute"
              onChange={() => {
                setUserConsent(!userConsent)
              }}
            />
            <label className="df-icon-unchecked" htmlFor="user-consent-checkbox">
              <IconUnCheck/>
            </label>
            <label className="df-icon-checked" htmlFor="user-consent-checkbox">
              <IconCheck/>
            </label>
          </span>
          {/* TODO: add correct hyperlinks below */}
          <label className="text-sm theme-text2-color">
            {step.user_consent_model.consent_description}
          </label>
        </div>

        {!submitting && <div className="space-x-4 x-sm:space-x-0">
          <button className="consent-button-cancel rounded-full py-3 px-24 mb-5 x-sm:w-full"
                  onClick={handleSaveAndContinueApplication}>{step.second_action_title}</button>

          <button className={`consent-button-submit rounded-full py-3 px-24 text-white x-sm:w-full ${userConsent ? '': 'opacity-25'}`}
                  disabled={!userConsent}
                  onClick={handleSubmit}>{step.first_action_title}</button>
        </div>}
        {submitting && <BtnSpinnerNext />}
      </div>
    );
  } else {
    return <ResumeApplication workflow={props.workflow} />
  }
}

export default Agreement;
