import React, {useEffect} from 'react';
import BtnSpinnerNext from '../components/BtnSpinnerNext';

type Props = {
  handleSubmit: Function,
  currentStep: any
};

function RefreshToken(props: Props) {
  const { handleSubmit, currentStep } = props;
  const template = currentStep["ui_template_type"];
  const step = currentStep[template];

  useEffect(() => {
    handleSubmit({});
  }, [handleSubmit]);

  return (
    <div>
      <p className="text-main mb-10">{step.upload_state.title}</p>
      <div className="content-container">
      <BtnSpinnerNext />
      </div>
    </div>
  );
}

export default RefreshToken;
