
import { buildAppleSignInUrl, buildGoogleSignInUrl } from "../utils/helpers";
import { IMAGE_PREFIX } from "../constants/urls.constants";
import { Link } from "react-router-dom";
import { track } from "../utils/analytics";
import { EVENT } from "../constants/events.constants";
import { ROUTE_EMAIL_SINGN_IN_PATH } from "../constants/route-paths.constants";

export function OldSignInFlowActions() {
  return (
    <div className="x-sm:flex x-sm:justify-between x-sm:flex-row inline-flex flex-col">
      <div className="mt-6 x-sm:w-1/4">
        <Link
          to="#"
          onClick={(e) => {
            e.preventDefault();
            track(EVENT.login_with_appleId);
            window.location.href = buildAppleSignInUrl();
          }}
          className="btn-sign-in-earnest x-sm:w-full x-sm:h-14 x-sm:mb-0 text-center x-sm:p-0 x-sm:flex x-sm:justify-center border-black text-white border bg-black rounded-full mb-2 flex items-center">
          <img
            className="h-6"
            src={`${IMAGE_PREFIX}/apple-logo.svg`}
            alt="apple" />
          <span className="pl-4 x-sm:hidden">Get started with Apple</span>
        </Link>
      </div>
      <div className="mt-6 x-sm:w-1/3">
        <Link
          to="#"
          onClick={(e) => {
            e.preventDefault();
            track(EVENT.login_with_googleId);
            window.location.href = buildGoogleSignInUrl();
          }}
          className="btn-sign-in-earnest text-center x-sm:h-14 x-sm:mb-0 x-sm:w-full x-sm:p-0 x-sm:flex x-sm:justify-center  border-black text-black bg-white border rounded-full mb-2 flex items-center">
          <img
            className="h-6"
            src={`${IMAGE_PREFIX}/google-logo.png`}
            alt="google" />
          <span className="pl-4 x-sm:hidden">Get started with Google</span>
        </Link>
      </div>
      <div className="mt-6 x-sm:w-1/3">
        <Link
          to="#"
          onClick={(e) => {
            e.preventDefault();
            if (localStorage.getItem('email')) {
              localStorage.removeItem('email');
            }
            track(EVENT.login_with_email);
            window.location.href = ROUTE_EMAIL_SINGN_IN_PATH;
          }}
          className="btn-sign-in-earnest x-sm:h-14 text-center x-sm:mb-0 x-sm:w-full x-sm:p-0 x-sm:flex x-sm:justify-center  border-black text-black bg-white border rounded-full flex items-center">
          <img
            className="h-4"
            src={`${IMAGE_PREFIX}/email.svg`}
            alt="Get started with Email" />
          <span className="pl-4 x-sm:hidden">Get started with Email</span>
        </Link>
      </div>
    </div>
  );
}
