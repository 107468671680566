import React from "react";
import { IMAGE_PREFIX } from "../constants/urls.constants";
import { DASHBOARD_PATH } from "../constants/route-paths.constants";
import moment from "moment";
import { paymentMessage } from "../constants/payment.constants";

type Props = { meta: any; partnerName: String; first_name: String };

function PaymentSucess(props: Props) {
  const logo_image = props.partnerName === 'krowdfit' || props.partnerName === 'customers_bank' || props.partnerName === 'amc' || props.partnerName === 'blockfi' || props.partnerName === 'slm_ignite' ? `${IMAGE_PREFIX}/white_logo.png` : `${IMAGE_PREFIX}/logo.png`;

  return (
    <div
      className={`x-sm:min-h-full dashboard-${props.partnerName} container-dashboard pt-4 lg:pt-10 pr-7 lg:pr-12 pb-10 lg:pb-0 pl-7 lg:pl-12 inset-0 z-50 fixed flex flex-col`}
    >
      <div data-testid="logo" className="fixed">
        <img className="w-20" src={logo_image} alt="logo" />
      </div>
      <div className="x-sm:mt-20 md:mt-20 lg:mt-28 flex x-sm:flex-col md:flex-col lg:flex-row lg:justify-between items-center">
        <div className="text-center lg:text-left x-sm:mr-0 lg:mr-0 basis-[30%] payment-success-thanks-message">
          <p className="text-xs mb-2.5 text-c1">
            {props?.meta?.schedule_created_at}
          </p>
          <p className="text-2xl font-semibold text-heading-2 thanks-first-name">
            Thanks for your payment, {props.first_name}!
          </p>
          <p className="text-xs mt-6 text-c1">PAYMENT AMOUNT</p>
          <p className="text-7xl font-bold lg:mb-9 mt-4 text-number-1">${props?.meta?.amount}</p>
        </div>
        <div className="payment-success-image">
          <img src={`${IMAGE_PREFIX}/Thanks.png`} alt="success" />        
        </div>
        <div className="mt-8 md:mt-16 lg:mt-0 payment-success-processing-message">
          <div className={`paymentInfo md:mx-auto rounded-3xl px-7 lg:pl-10 lg:pr-8 py-5 paymentCard mb-3.5 md:mb-12 paymentCard-${props.partnerName}`}>
            <p className="text-lg font-bold text-t1-color text-c2-std pb-4">{paymentMessage.paymentSuccess.title}</p>
            <p className="text-xxs text-t1-color text-c2-std">
              {`${paymentMessage.paymentSuccess.message} `}
              {props?.meta?.amount}
            </p>
          </div>
          <div className="text-center">
            <button
              className="h-16 w-28 focus:outline-none font-bold text-base rounded-full bg-white error-block px-8 py-3"
              onClick={() => window.location.assign(DASHBOARD_PATH)}
            >
              {paymentMessage.paymentSuccess.btnDone}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentSucess;
