import React, { useState } from "react";

const AppTooltip = (props) => {
    const [active, setActive] = useState(false);

    const showTip = () => setActive(true);

    const hideTip = () => setActive(false);


    return (
        <div
            className={`App-Tooltip-Wrapper x-sm:pr-14 mt-1 mr-20`}
            style={{ marginLeft: "0px" }}
            onMouseEnter={showTip}
            onMouseLeave={hideTip}
        >
            {props.children}
            {active && (
                <div className={`App-Tooltip-Tip x-sm:flex x-sm:mt-5 top x-sm:-left-full`}>
                    {props.text}
                </div>
            )}
        </div>
    );
};

export default AppTooltip;
