import React from "react";
import {get} from "../utils/client";
import Loading from "../components/Loading";
import emitter from "../utils/emitter";
import {IMAGE_PREFIX, WORKFLOW_ENDPOINT} from "../constants/urls.constants";
import {track} from "../utils/analytics";

type NextStepsProps = {};
type NextStepsState = { workflow: Object, redirectToLogin: boolean };

class NextSteps extends React.Component<NextStepsProps, NextStepsState> {
  constructor(props) {
    super(props);
    this.state = {workflow: null, redirectToLogin: false};
  }

  componentDidMount() {
    get(`${WORKFLOW_ENDPOINT}?current_step=card_approved`)
      .then(response => this.setState({workflow: response}))
  }


  render() {
    if (this.state.workflow) {
      const template = this.state.workflow["steps"][0]["ui_template_type"]
      const step = this.state.workflow["steps"][0][template];
      const identifier = this.state.workflow["steps"][0].identifier;
      const breadcrumbs = this.state.workflow['breadcrumbs'];

      emitter.emit('progress', {breadcrumbs, identifier});

      const imgSrcWeb = `${IMAGE_PREFIX}/${step.web.desktop_mode.image_name}.png`;

      return (
        <div className='md:absolute w-full md:w-4/5 bottom-0 left-0 top-12 md:top-0'>
          <div className='hidden md:block mt-28'>
            <img className='mx-auto' src={imgSrcWeb} alt='Deserve Cards'/>
          </div>
          <div className="hidden md:block text-main text-center mt-14">
            {step.web.desktop_mode.default_state.title1}
          </div>
          <div className="hidden md:block text-main text-center">
            {step.web.desktop_mode.default_state.title2}
          </div>
          <div className="md:hidden text-main mt-12">
            {step.web.mobile_mode.default_state.title}
          </div>
          <div className='md:flex md:justify-around mt-14 md:mt-14'>
            <div className='flex md:block w-full md:w-3/12'>
              <div className='w-12 h-12 rounded-full nextSteps-stepNos md:mx-auto p-3 text-center text-white'>
                {step.template_h_rows[0].step}
              </div>
              <div className='w-9/12 md:w-full ml-5 md:ml-0'>
                <div className='w-full md:pt-3.5 text-lg font-semibold md:text-center'>
                  {step.template_h_rows[0].title}
                </div>
                <div className='w-full text-lg md:text-center'>
                  {step.template_h_rows[0].subtitle}
                </div>
              </div>
            </div>
            <div className='flex md:block w-full md:w-3/12 mt-6 md:mt-0'>
              <div
                className='w-12 h-12 rounded-full nextSteps-stepNos md:mx-auto p-3 text-center text-white mt-4 md:mt-0'>
                {step.template_h_rows[1].step}
              </div>
              <div className='w-9/12 md:w-full ml-5 md:ml-0'>
                <div className='w-full md:pt-3.5 text-lg font-semibold md:text-center'>
                  {step.template_h_rows[1].title}
                </div>
                <div className='w-full text-lg md:text-center'>
                  {step.template_h_rows[1].subtitle}
                </div>
              </div>
            </div>
            <div className='flex md:block w-full md:w-3/12 mt-6 md:mt-0'>
              <div
                className='w-12 h-12 rounded-full nextSteps-stepNos md:mx-auto p-3 text-center text-white mt-4 md:mt-0'>
                {step.template_h_rows[2].step}
              </div>
              <div className='w-9/12 md:w-full ml-5 md:ml-0'>
                <div className='w-full md:pt-3.5 text-lg font-semibold md:text-center'>
                  {step.template_h_rows[2].title}
                </div>
                <div className='w-full text-lg md:text-center'>
                  {step.template_h_rows[2].subtitle}
                </div>
              </div>
            </div>
          </div>
          <div
            className='md:hidden mt-16 text-center text-xl tracking-tight button-with-custom-text h-16 p-5 cursor-pointer text-white'>
            <a href={step.template_h_rows[3].download_url} onClick={() => {
              track('pqAppDownloadApp')
            }}>{step.template_h_rows[3].action_title}</a>
          </div>
        </div>
      );
    } else {
      return (<Loading/>);
    }
  }
}

export default NextSteps;
