import React from 'react';
import { useLocation } from 'react-router-dom';
import * as queryString from "query-string";
import { APPLICATION_BASE_URL, IMAGE_PREFIX } from "../constants/urls.constants";

function ErrorOccurred(props: any) {
  const appConfig = window['appConfig'] ?? {};
  let queryParams = useLocation().search;
  let params = queryParams ? queryString.parse(queryParams.replace('?', '')) : {}
  let errorMessage = params['errorMessage'];
  let errorAction = params['errorAction'];
  let imgPath = `${IMAGE_PREFIX}/505.png`;

  function handleClick(e: any) {
    e.preventDefault();
    window.location.href = APPLICATION_BASE_URL;
  }

  return (
    <div className={`app-container-${appConfig.PARTNER_NAME} signin-flow-page text-center`} data-testid='title'>
      {errorMessage ? <p className="text-main text-1-heading">{errorMessage}</p> : <p className="text-main text-1-heading">Something went wrong. <br /> Try reloading the page.</p>}
      <img src={imgPath} alt="error occurred" className="w-2/3 mx-auto my-4" />
      <button data-testid='button-title' className="bg-theme rounded-full py-3 px-28 text-white focus:outline-none" onClick={handleClick}>
        {errorAction ? <label>{errorAction}</label> : <label>Reload</label>}
      </button>
    </div>
  )
}

export default ErrorOccurred;
