export let numberFormatter = (amount: number, showDollar: boolean = true) => {
   let formatter = new Intl.NumberFormat('en-US', {
        ...showDollar ? {
          style: 'currency',
          currency: 'USD',
        } : {},
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      });

  let formattedAmount = amount !== null && !isNaN(amount) ? formatter.format(amount) : amount;
  return formattedAmount;
}
