import React, { useEffect } from "react";
import Toast from "../../components/Toast";

import { IMAGE_PREFIX } from "../../constants/urls.constants";
import {
  clearHomeRouteState,
  getCachedPhoneNumber,
  trackUTM,
} from "../../utils/helpers";
import { useHistory, useLocation } from "react-router-dom";
import { AutoHideToast } from "../../components/AutohideToast";
import SLMIgniteFAQView from "../../components/SLMIgniteFAQView";

const SLMIgniteFAQ = (props) => {
  const location: any = useLocation();
  const history: any = useHistory();
  const { title } = location.state || {};
  const params = new URLSearchParams(location.search);
  const message = params.get("message");
  const phoneNumber = getCachedPhoneNumber();

  trackUTM("marketingData", window.location.href); // Track marketing data

  useEffect(() => {
    clearHomeRouteState(title, history);
  }, []);

  return (
    <div className="bg-gray-50 ignite-index-page">
      <div className="md:container m-auto lg:px-8">
        {title && <AutoHideToast title={title} timeout={6000} />}
        <div className="w-full x-sm:pb-0 py-7 flex flex-col">
          {message && (
            <Toast
              cssClasses="w-1/3 x-sm:w-full m-auto absolute top-6 x-sm:top-2 left-0 right-0 z-50 bg-white x-sm:p-4 text-center"
              text={message}
              timeout={6000}
            />
          )}
          <div className="flex justify-between w-40 x-sm:pl-8 mr-auto">
            <a className="inline-block" href="/">
              <img src={`${IMAGE_PREFIX}/logo.png`} alt="logo" />
            </a>
          </div>
          <div className="cb-content-container x-sm:px-8 x-sm:pt-0.5 pb-4 flex flex-col lg:flex-row-reverse justify-center lg:justify-between">
            <img
              src={`${IMAGE_PREFIX}/hero-card.png`}
              className="customersbank-card-img x-sm:mx-auto x-sm:w-10/12 lg:ml-24 w-1/3"
              alt="cubicard"
            />
            <div className="lg:w-2/5 lg:self-center x-sm:mt-8 x-sm:mb-8">
              <h1 className="x-sm:text-3xl text-center lg:text-left page-main-heading">
                <span className="block">Ignite your card today</span>
              </h1>
              <p className="page-sub-heading-text mt-6 lg:w-80 x-sm:text-center md:text-center lg:text-left">
                Earn unlimited cash back on every purchase, every day.
              </p>
            </div>
          </div>
        </div>
        <SLMIgniteFAQView />
      </div>
      <footer className="md:mt-20 lg:mt-20 py-16 bg-slmignitebank">
        <div className="md:container m-auto lg:px-8 x-sm:px-8 md:px-8">
          <div className="flex justify-between">
            <div className="footer-text">© 2023 Ignite</div>
            <ul className="flex">
              <li className="pl-2">
                <a href="https://static.cards.theignitecard.com/resources/ignite/privacy_policy.pdf" target="_blank" className="footer-text">
                  Privacy Policy
                </a>
              </li>
              <li className="pl-2">
                <a href="https://static.cards.theignitecard.com/resources/ignite/website_terms_of_use.pdf" target="_blank" className="footer-text">
                  Terms of Use
                </a>
              </li>
            </ul>
          </div>
          <div className="mt-8">
            <p className="footer-text">
              Issued by First Bank & Trust, Brookings, SD, Member FDIC, pursuant to a license by Mastercard®.
            </p>
            <p className="footer-text">
              All product names, logos, and brands are property of their respective owners. All company, product and service marks used in this website are for identification purposes only. Use of these names, logos, and brands does not imply endorsement.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default SLMIgniteFAQ;
