import React, { useEffect } from 'react';
import Toast from '../../components/Toast';

import { IMAGE_PREFIX } from "../../constants/urls.constants";
import { clearHomeRouteState, clearRouteState, getCachedPhoneNumber, isNewSignInFlowEnabled, trackUTM } from "../../utils/helpers";
import { useHistory, useLocation } from 'react-router-dom';
import { NewSignInFlowActions } from '../../components/NewSignInFlowActions';
import { OldSignInFlowActions } from '../../components/OldSignInFlowActions';
import { AutoHideToast } from '../../components/AutohideToast';

function Earnest() {
  const location: any = useLocation();
  const history: any = useHistory();
  const { title } = location.state || {};
  const params = new URLSearchParams(location.search);
  const message = params.get("message");
  const phoneNumber = getCachedPhoneNumber();

  const footerLinks = {
    termsAndConditions: '#',
    electronicConsentPolicy: 'https://website-static.deserve.com/wp-content/uploads/2020/01/Web-Electronic-Consent-Policy-Rev-012020-copy.pdf',
    patriotActUSA: 'https://website-static.deserve.com/wp-content/uploads/2019/11/USA-PATRIOT-Act-Notice.pdf',
    privacyPolicy: 'https://www.deserve.com/privacy-policy',
    privacyNotice: 'https://website-static.deserve.com/wp-content/uploads/2019/11/PrivacyNotice.pdf',
    termsOfService: '#'
  };

  trackUTM('marketingData', window.location.href); // Track marketing data

  useEffect(() => {
    clearHomeRouteState(title, history);
  }, []);

  return (
    <>
      {title && <AutoHideToast title={title} timeout={6000} />}
      <div className="bg-earnest w-full lg:min-h-screen x-sm:min-h-full px-20 x-sm:px-8 pt-7">
        {message && <Toast cssClasses="w-1/3 x-sm:w-full m-auto absolute top-6 x-sm:top-2 left-0 right-0 z-50 bg-white x-sm:p-4 text-center" text={message} timeout={6000} />}
        <div className="flex justify-between mb-14 x-sm:mb-4 x-sm:items-center">
          <a className="inline-block " href="/"><img src={`${IMAGE_PREFIX}/logo.svg`} alt="logo" /></a>
          <img className="inline-block x-sm:ml-16 x-sm:text-xs h-7" src={`${IMAGE_PREFIX}/poweredby.svg`} alt="powered by deserve" />
        </div>
        <div className="er-content-container x-sm:pt-0.5 pb-4 flex flex-col">
          {!phoneNumber && <>
            <h1 className="theme-text-color x-sm:text-4xl text-6xl font-circular x-sm:leading-10 font-semibold x-sm:mt-7 text-center lg:text-left">Fast. Digital. <br /> Contactless.</h1>
            <p className="theme-text-color text-2xl x-sm:leading-8 x-sm:mt-3 mt-4 font-circular text-center lg:text-left">It’s more than a credit card, <br /> it’s an experience.</p>
            <p className="theme-text-color text-xs mt-2 mb-2 x-sm:mb-4 font-circular text-center lg:text-left">See if you qualify with no negative impact to your credit*</p>
          </>}
          <div className="x-sm:w-full x-sm:h-0.5 x-sm:my-2.5 x-sm:bg-gray-200" />
          {phoneNumber && <>
            <h1 className="theme-text-color text-6xl font-circular x-sm:leading-10 font-semibold x-sm:text-4xl x-sm:mt-4 text-center lg:text-left">Welcome back</h1>
            <p className="theme-text-color text-xl x-sm:text-lg x-sm:mt-2 mt-4 md:mb-2 font-circular text-center lg:text-left">Continue with your phone number <span className='block'>verification to sign in</span></p>
          </>}
          <div className="lg:hidden 2xl:hidden xl:hidden sm:hidden x-sm:flex x-sm:justify-center x-sm:pt-4">
            <span>Sign in or Apply below</span>
          </div>
          {isNewSignInFlowEnabled() ? <NewSignInFlowActions /> : <OldSignInFlowActions />}
          <p className="theme-text-color mt-4 x-sm:pt-0 text-xs text-center lg:text-left">*Subject to credit approval</p>
        </div>
      </div>
      <img src={`${IMAGE_PREFIX}/home-img.png`} className='lg:hidden' />
      <div className="earnest-footer bg-white text-xs px-20 pt-14 pb-28">
        <ul className="font-bold">
          <li><a href={footerLinks.termsAndConditions}>Terms and Conditions</a></li>
          <li><a href={footerLinks.electronicConsentPolicy}>Electronic Consent Policy</a></li>
          <li><a href={footerLinks.patriotActUSA}>PATRIOT Act Notice</a></li>
          <li><a href={footerLinks.privacyPolicy}>Privacy Policy</a></li>
          <li><a href={footerLinks.privacyNotice}>Privacy Notice</a></li>
          <li><a href={footerLinks.termsOfService}>Terms of Service</a></li>
        </ul>
        <span>&#169; 2022 Deserve</span>
        <div className="text-blue mb-10 mt-3">
          <p>
            The Earnest credit card is Powered by Deserve and issued by Celtic Bank, a Utah-Chartered Industrial Bank, Member FDIC.
          </p>
          <p>
            All product names, logos, and brands are property of their respective owners. All company, product and service marks used in this website are for identification purposes only. Use of these names, logos, and brands does not imply endorsement.
          </p>
        </div>
      </div>
    </>
  );
}

export default Earnest;
