import React, { useState, useEffect, Fragment, useRef, useContext } from "react";
import {
  IMAGE_PREFIX,
  IMAGE_BASE_URL,
  TRANSACTIONS_API_URL,
  STATEMENTS_API_URL,
  STATEMENTS_DOWNLOAD_API_URL,
} from "../constants/urls.constants";
import { get } from "../utils/client";
import { numberFormatter } from "../utils/numberFormatter";
import {DownloadOrViewFile, getApplicationToken, confirmOnlyDashboardPage} from "../utils/helpers";
import Cookies from "js-cookie";
import axios from "axios";
import { AccountDataContext } from "./AccountDataContext";
import IconStatements from "./IconStatements";
type Props = {partnerName: String };

function Sidebar(props: Props) {
  const { accountData } = useContext(AccountDataContext) || {};
  let isDashboardPage = useRef(confirmOnlyDashboardPage());
  const [transactionsData, setTransactionsData] = useState(null);
  const [statementsData, setStatementsData] = useState(null);
  const [statementsView, setView] = useState(false);
  const [HasTransactionApiFailed, setHasTransactionApiFailed] = useState(false);
  const bgCss = props.partnerName === 'amc' ? 'bg-black' : 'bg-white';
  const profileSidebarClasses = 'md:fixed md:top-0 md:right-0 md:w-1/4';
  const dashboardSidebarClasses = 'lg:fixed lg:top-0 lg:right-0 lg:w-1/4';
  const sidebarClasses = `w-full ${!isDashboardPage.current ? profileSidebarClasses : dashboardSidebarClasses} h-screen overflow-y-auto ${bgCss} dashboard-transactions p-8 sidebar-${props.partnerName}`;
  const closeImage = props.partnerName === 'amc' ? `${IMAGE_PREFIX}/close-page.png` : `${IMAGE_BASE_URL}/close.png`

  const customMessages = {
    downloadStatementMessage: 'Come back to view and download your statements here',
    transactionApiFailedMessage: `Oh no! Something's not right`
  }


  useEffect(() => {
    get(TRANSACTIONS_API_URL).then((res) => {
      if (res.hasError) {
        setHasTransactionApiFailed(true);
      } else {
        setTransactionsData(res);
      }
    }).catch((err) => {

    });
  }, []);

  const getStatements = () => {
    get(STATEMENTS_API_URL).then((data) => {
      setStatementsData(data);
      setView(true);
    }).catch((err) => { });
  };

  const downloadStatement = (id, title) => {
    const applicationToken = getApplicationToken();
    return axios({
      url: `${STATEMENTS_DOWNLOAD_API_URL}?stmtid=${id}`,
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${applicationToken}`,
      },
    }).then((res) => {
      DownloadOrViewFile(
        res.data,
        "application/pdf",
        `Statement-${title}.pdf`,
        true);
      return res;
    }).catch((err) => { });
  };

  return (
    <>
      <aside
        id="transaction-sidebar"
        className={sidebarClasses}
      >
        {!statementsView && (
          <div className="flex flex-row justify-between items-baseline mb-10">
            <div>
              <div className="text-2xl capitalize dashboard-transactions-header font-bold mb-2 text-heading-2">
                recent <br /> transactions
              </div>
              <div>
                <p className="text-b5">
                  {transactionsData?.sub_title ? transactionsData.sub_title : null}
                  <br />
                </p>
              </div>
            </div>
            <span className="cursor-pointer rounded-2xl"
              style={{width: "35px"}}
              title="Your Statements"
              onClick={getStatements}>
              <IconStatements fillColor={props.partnerName === 'amc' ? "#fff" : "rgba(0, 0, 0, 0.02)"} />
            </span>
          </div>
        )}
        {statementsView && (
          <div className="flex flex-row justify-between items-baseline">
            <div className="text-2xl capitalize dashboard-transactions-header font-bold mb-10 text-heading-2">
              your <br /> statements
            </div>
            <img
              className="cursor-pointer"
              width="25px"
              src={closeImage}
              alt="transactions"
              title="Your Statements"
              onClick={() => setView(false)}
            />
          </div>
        )}
        {!statementsView && (
          <div>
            {!HasTransactionApiFailed &&
              transactionsData &&
              transactionsData.sections ? (
              <div className="tk-europa">
                {transactionsData.sections.map((section) => {
                  return (
                    <div key={section.id} className="divide-y divide-gray-200">
                      <span className="font-bold">{section.title}</span>
                      {section.transactions.map((t) => {
                        const [month, date] = new Date(
                          t.date_transaction * 1000
                        )
                          .toLocaleDateString("en-US", {
                            month: "short",
                            day: "numeric",
                          })
                          .split("/");

                        return (
                          <div
                            key={t.id}
                            className="mb-3 flex flex-row py-3 items-center"
                          >
                            <img
                              className="inline-block mr-2 rounded"
                              width="25px"
                              src={t.merchant.thumbnail_url}
                              alt="merchant"
                            />
                            <div className="flex flex-col">
                              <small className="text-gray-500 uppercase">
                                {month}&nbsp;{date}
                              </small>
                              <span>{t.merchant.name}</span>
                            </div>
                            <span className="ml-auto font-bold">
                              {numberFormatter(t.amount)}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            ) : (
              <Fragment>
                {accountData.payment && <p className="dashboard-transactions-content text-b5">
                  {accountData.payment.payment_home_card?.is_migration_in_progress
                    ? accountData.payment.payment_home_card.summary.text
                    : HasTransactionApiFailed
                      ? customMessages.transactionApiFailedMessage
                      : transactionsData?.footer?.message
                  }
                  </p>
                }
                <img
                  width="220px"
                  src={`${IMAGE_PREFIX}/card.png`}
                  alt="card"
                  className="mx-auto"
                />
              </Fragment>
            )}
          </div>
        )}
        {statementsView && (
          <div>
            {statementsData &&
              statementsData.sections &&
              statementsData.sections.length ? (
              <div className="tk-europa">
                {statementsData.sections.map((section) => {
                  return (
                    <div
                      key={section?.header?.title}
                      className="divide-y divide-gray-200"
                    >
                      <span className="font-bold">
                        {section?.header?.title}
                      </span>
                      {section?.months.map((t: any) => {
                        return (
                          <div
                            key={t.id}
                            className="flex flex-row py-3 items-center"
                          >
                            <img
                              className="inline-block mr-2"
                              width="25px"
                              src={`${IMAGE_BASE_URL}/statement.png`}
                              alt="statement"
                            />
                            <div className="flex flex-col">{t?.title}</div>
                            <span className="ml-auto font-bold">
                              ${t.current_balance}
                            </span>
                            <img
                              className="inline-block ml-3  cursor-pointer"
                              width="25px"
                              src={`${IMAGE_BASE_URL}/download.png`}
                              alt="download"
                              title="Download Statement"
                              onClick={() => downloadStatement(t?.id, t?.title)}
                            />
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            ) : (
              <Fragment>
                {accountData.payment && <p className="dashboard-transactions-content">
                  {accountData.payment.payment_home_card?.is_migration_in_progress
                    ? accountData.payment.payment_home_card.summary.text
                    : customMessages.downloadStatementMessage
                  }
                  </p>
                }
                <img
                  width="220px"
                  src={`${IMAGE_PREFIX}/card.png`}
                  alt="card"
                  className="mx-auto"
                />
              </Fragment>
            )}
          </div>
        )}
      </aside>
    </>);
}

export default Sidebar;