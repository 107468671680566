import React, {useState, useRef, useEffect} from 'react';
import BtnNext from "../components/BtnNext";
import { alphabets } from '../utils/alphabets';
import emitter from "../utils/emitter";
import {track} from "../utils/analytics";
import BtnSpinnerNext from '../components/BtnSpinnerNext';
import {EVENT} from "../constants/events.constants";
import {findInCollection} from "../utils/find-in-collection";

type Props = { CurrentStep: { ui_template_type: string }; handleSubmit: Function; breadcrumbs: Object; showSpinner: boolean; name: string, event: string};

function TemplateF(props: Props) {
  const [submitting, setSubmitting] = useState(props.showSpinner);
  const [HasError, setHasError] = useState(false);
   const ModelName = props.CurrentStep.ui_template_type;
  const ModelData = props.CurrentStep[ModelName];
  const title = ModelData.default_state.title;
  const FormRef = useRef(null);
  let key = props.name;

  useEffect(() => {
    handleChange()
    emitter.emit('progress', { breadcrumbs: props.breadcrumbs, identifier: props.CurrentStep['identifier'] });
  }, [props.CurrentStep, props.breadcrumbs]);


  function handleChange() {
    setHasError(false);
    const inputEl =
      FormRef.current &&
      FormRef.current.querySelector(`input[name="${key}"]:checked`);

    if (inputEl && inputEl.value) {
      setHasError(false);
    } else {
      setHasError(true);
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    const modelName = props.CurrentStep.ui_template_type;
    const modelData = props.CurrentStep[modelName];
    const inputEl =
      FormRef.current &&
      FormRef.current.querySelector(`input[name="${key}"]:checked`);

    setSubmitting(true);

    if (inputEl && inputEl.value) {
      track(props.event);
      setSubmitting(true);

      let selectedAction = findInCollection(modelData.actions, 'value', inputEl.value);
      let data = {}
      data[key] = inputEl.value
      data['optional'] = selectedAction && selectedAction.step_skip ? {'forward_count': selectedAction.step_skip.forward_count} : {}
      props.handleSubmit(data)
      .catch(handleSpinner);
    } else {
      setHasError(true);
    }
  }



  function handleSpinner(resp?: any) {
    setSubmitting(false);
  }

  return (
    <div key={props.CurrentStep['step_info'].step_identifier} id={props.CurrentStep['step_info'].step_identifier}>
      <p className="text-main x-sm:mb-10 mb-10" data-testid="title">{title}</p>
      <div className="content-container">
        <form ref={FormRef} noValidate onSubmit={handleSubmit}>
          <div className="grid gap-2 x-sm:gap-3 grid-cols-1 md:grid-cols-2 mb-10 theme-radio-select-wrap">
            {ModelData.actions.map((action: any, index: number) => {
              return (
                <React.Fragment key={action.identifier}>
                  <input
                    type="radio"
                    name={key}
                    value={action.value}
                    id={action.identifier}
                    key={action.identifier}
                    data-testid={action.identifier}
                    className="invisible absolute"
                    onChange={handleChange}
                  />
                  <label
                    htmlFor={action.identifier}
                    className="theme-radio-select cursor-pointer p-5 rounded-full text-large-btn x-sm:text-center"
                  >
                    <small className="radio-select-index border rounded-full inline-block w-5 h-5 text-center mr-2 x-sm:hidden">
                      {alphabets[index].toUpperCase()}
                    </small>
                    {action.action_title}
                  </label>
                </React.Fragment>
              );
            })}
          </div>
          {!props.showSpinner && <div className="text-center">
            <BtnNext data-testid="next-btn" onClick={handleSubmit} className={HasError ? 'opacity-25 inline-block' : ''} />
          </div>}
          {(props.showSpinner) && <BtnSpinnerNext />}
        </form>
      </div>
    </div>
  )
}

export default TemplateF;
