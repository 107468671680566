import React, { useEffect } from "react";
import emitter from '../utils/emitter';
import { IMAGE_PREFIX ,LOGIN_URL } from "../constants/urls.constants";
import { autoPoll } from "../utils/auto_poll";
import LogoutIcon from "../components/LogoutIcon";
import { clearApplicationToken} from "../utils/helpers";

type Props = {
  breadcrumbs: Object,
  workflow: Object,
  setWorkflow: Function,
  currentStep: Object | any
};

function DashboardBanner(props: Props) {
  const template = props.workflow["steps"][0]["ui_template_type"]
  const step = props.workflow["steps"][0][template];
  const subtitle = step.subtitle;

  const onbUploadingGif = `${IMAGE_PREFIX}/${step.image.name}.${step.image.type}`

  useEffect(() => {
    const intervalId = props.workflow["steps"][0].auto_poll && autoPoll(props.workflow, props.setWorkflow);

    emitter.emit('progress', {
      breadcrumbs: props.breadcrumbs,
      identifier: props.currentStep.step_info.step_identifier,
    });

    return () => {
      clearInterval(intervalId);
    }
  }, [props.breadcrumbs, props.currentStep, props.setWorkflow, props.workflow]);

  const logout = () =>  {
    clearApplicationToken();
    window.location.href = LOGIN_URL;
  }

  return (
    <div className='absolute w-full md:w-4/5 bottom-0 left-0 top-12 md:top-0 x-sm:top-0 h-full flex flex-1 items-center document-status'>
      <div className="content-container m-auto x-sm:w-full x-sm:mb-0">
        <div className="flex justify-end mr-7 x-sm:mr-3 absolute top-8 right-0 x-sm:top-48">
          <span id="logout" className="btn-blur-toggle rounded-full" data-testid="logoutButton" title="Log Out" onClick={logout} >
            <LogoutIcon fillColor="#4064f3" />
          </span>
        </div>
        <img src={onbUploadingGif} data-testid="onbUploadingGif" alt="onbUploadingGif" className="w-96 mx-auto m-auto x-sm:w-4/5" />
        <p data-testid="subtitle" className='text-main text-center z-20 mt-4 mb-0 px-40 md:text-3xl x-sm:text-2xl x-sm:px-4 x-sm:mt-2 x-sm:mb-8 2xl:px-44 '>{subtitle}</p>
      </div>
    </div>
  );
}

export default DashboardBanner;
