import React from 'react';
import { IMAGE_PREFIX } from "../constants/urls.constants";

type Props = {
  className?: string,
}

function Loading(props: Props) {
  let imgSrc = `${IMAGE_PREFIX}/loading.gif`
  return (
    <div data-testid="loader" className={`flex flex-1 items-center justify-items-center h-full absolute inset-0 text-center x-sm:w-full md:w-4/5 ${props.className}`}>
      <img src={imgSrc} alt="loading" className="m-auto w-20" />
    </div>
  )
}

export default Loading;
