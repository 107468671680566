import React, {useState, useEffect} from 'react';
import emitter from "../utils/emitter";
import {track} from "../utils/analytics";
import * as Scroll from 'react-scroll';
import {
  IMAGE_PREFIX,
  IMAGE_BASE_URL
} from "../constants/urls.constants";
import AppModal from "../components/AppModal";
import { QRCodeSVG } from "qrcode.react";
import ReactHtmlParser from "html-react-parser";
import { numberFormatter } from '../utils/numberFormatter';
import { cardLogo } from "../utils/constants";
import { autoPoll } from "../utils/auto_poll";

type Props = { 
  CurrentStep: { ui_template_type: string }; 
  breadcrumbs: Object;
  workflow: Object,
  setWorkflow: Function
};

function TemplateM(props: Props) {
  const [showModal, setShowModal] = useState(false);
  let partnerName = window['appConfig'].PARTNER_NAME;
  const cardCSS = `relative mx-auto paymentApprovalCard pt-5 sm:pt-8 lg:pt-5 pb-3.5 sm:pb-4 px-5 rounded-xl`;
  let Link = Scroll.Link;
  const ModelName = props.CurrentStep.ui_template_type;
  const ModelData = props.CurrentStep[ModelName];
  const title = ModelData.default_state.title;
  const next_steps = ModelData.next_steps;
  const logo_image = `${IMAGE_PREFIX}/${cardLogo[partnerName]}.png`;
  const mastercard_image = `${IMAGE_BASE_URL}/mastercard.png`;
  const visa_image = `${IMAGE_BASE_URL}/visa.png`;
  const id_image = `${IMAGE_BASE_URL}/${next_steps.steps[0].left_container.left_image.name}.png`;
  const card_image = `${IMAGE_BASE_URL}/${next_steps.steps[1].left_container.left_image.name}.png`;
  const QRCodeImageURL = window["appConfig"]["CLIENT_APP_DOWNLOAD_URL"];

  useEffect(() => {
    const intervalId = autoPoll(props.workflow, props.setWorkflow);
    emitter.emit('progress', { breadcrumbs: props.breadcrumbs, identifier: props.CurrentStep['identifier'] });
    return () => {
      clearInterval(intervalId);
    }
  }, [props.CurrentStep, props.breadcrumbs, props.workflow, props.setWorkflow]);

  track('onb_credit_approved_viewed');

  const handleNext = (e: Event) => {
    e.preventDefault();
    let userAgent = navigator.userAgent;

    track("onb_credit_approved_continue_clicked", {download_url: QRCodeImageURL});

    if (
      userAgent.match(/Android/i) ||
      userAgent.match(/iPhone/i) ||
      userAgent.match(/iPad/i)
    ) {
      track("onb_app_download_mobile_web_button_clicked", {download_url: QRCodeImageURL});
      window.location.href = QRCodeImageURL;
    } else {
      track("onb_app_download_button_clicked", {download_url: QRCodeImageURL});
      setShowModal(true);
    }
    return null;
  }

  const onCloseClick = (e: Event) => {
    e.preventDefault();
    setShowModal(false);
  }

  return (
    <div key={props.CurrentStep['step_info'].step_identifier} id={props.CurrentStep['step_info'].step_identifier}>
      <p className="profile-page-title theme-text-color text-3xl md:text-4xl lg:text-3xl xl:text-4xl px-14 mt-28 lg:mt-0 mb-16 lg:mb-4 xl:mb-8 2xl:mb-6 text-center" data-testid="title">{ReactHtmlParser(title)}</p>
      <div className="content-container congrats-content">
        <div className="text-center mt-5">
          <div className={cardCSS}>
            <div className='absolute'><img className={`${window['appConfig'].PARTNER_NAME}-card-logo`} src={logo_image} alt="logo" /></div>
            <div className="w-11/12 flex flex-row justify-between absolute bottom-3.5 sm:bottom-6 lg:bottom-3">
              <div className="">
                <span className='approvedAmnt lg:text-4xl font-bold'>
                  {numberFormatter(ModelData.card.approved_amount.text.substring(1))}
                </span>
              </div>
              <div className="relative w-24 lg:w-16">
                <img
                  className="absolute bottom-1 lg:right-3 w-16 md:w-24 lg:w-16 h-11 md:h-16 lg:h-10"
                  src={partnerName === 'amc' ? visa_image : mastercard_image}
                  alt="powered by deserve"
                />
              </div>
            </div>
          </div>
        </div>
        <div className='text-center mt-24 lg:mt-10 2xl:mt-10 mb-14 sm:mb-8 px-16 theme-text-color congrats-page-subtitle'>
          {next_steps.sub_title}
        </div>
        <div className='text-center w-80 lg:w-56 h-16 mx-auto congrats-page-btn'>
          <Link
            to={`${1}`}
            containerId="containerElement"
            className='block h-full py-4 font-medium text-xl tracking-tighter onsent-button-submit cursor-pointer button-with-custom-text rounded-full text-white'
            onClick={handleNext}
          >
            {ModelData.default_state.first_action_title}
          </Link>
        </div>
      </div>

      <AppModal open={showModal} onClose={onCloseClick}>
        <div className="lg:flex px-14 lg:pl-12 lg:pr-14 xl:pr-20 pt-4 lg:pt-7 xl:pt-8 pb-20">
          <div className='lg:pt-5 xl:pt-0 lg:w-1/2'>
            { track("onb_app_qrcode_displayed", {download_url: QRCodeImageURL}) }
            { track("onb_next_steps_viewed", {download_url: QRCodeImageURL}) }
            <QRCodeSVG className='lg:hidden mx-auto' size={200} includeMargin={true} value={QRCodeImageURL} />
            <QRCodeSVG className='hidden lg:block xl:hidden mx-auto' size={180} includeMargin={true} value={QRCodeImageURL} />
            <QRCodeSVG className='hidden xl:block mx-auto' size={250} includeMargin={true} value={QRCodeImageURL} />
            <div className="font-light sub-text text-base mt-5 lg:mt-4 text-center">Scan to download</div>
          </div>
          <hr className='lg:hidden mt-14 mb-8' />
          <div className='lg:mr-0 lg:ml-24 xl:ml-28 lg:w-1/2'>
            <div className='text-3xl xl:text-4xl theme-text-color profile-page-title text-center lg:text-left'>{next_steps.title}</div>
            <div className="mt-2.5 font-normal text-xl theme-text-color lg:text-lg xl:text-2xl text-center lg:text-left">{next_steps.sub_title}</div>
            <div className='flex mt-6 lg:mt-6'>
              <div className='w-1/4'>
                <div className={`w-14 lg:w-12 xl:w-14 h-14 lg:h-12 xl:h-14 p-3 lg:p-2.5 rounded-full bgPopupCircle mr-5 lg:mr-4`}>
                  <div className='w-8 lg:w-7 xl:w-8 h-8 lg:h-7 xl:h-8'>
                    <img src={id_image} />
                  </div>
                </div>
              </div>
              <div className="w-3/4 font-light theme-text-color text-base lg:text-sm xl:text-base pt-1">{next_steps.steps[0].title.text}</div>
            </div>
            <hr className='mt-3.5 lg:mt-3 w-3/4 ml-auto mr-0' />
            <div className='flex mt-3.5 lg:mt-3'>
              <div className='w-1/4'>
                <div className={`w-14 lg:w-12 xl:w-14 h-14 lg:h-12 xl:h-14 pl-3.5 lg:pl-2.5 xl:pl-3.5 pt-4 lg:pt-3.5 xl:pt-4 rounded-full bgPopupCircle mr-5 lg:mr-4`}>
                  <div className='w-8 lg:w-7 xl:w-8 h-8 lg:h-7 xl:h-8'>
                    <img src={card_image} />
                  </div>
                </div>
              </div>
              <div className='w-3/4 pt-1'>
                <div className="font-light theme-text-color text-base lg:text-sm xl:text-base">{next_steps.steps[1].title.text}</div>
                <div className={`w-4/6 mt-1.5 py-0.5 px-2.5 sub-text txtCreditScore rounded-full font-normal italic`}>
                  <span className=''>{ReactHtmlParser(next_steps.steps[1].subtitle.text)}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AppModal>
    </div>
  )
}

export default TemplateM;
