import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { IMAGE_PREFIX } from "../constants/urls.constants";
import emitter from "../utils/emitter";
import Logo from "./Logo";
type Props = { partnerName: String };

function Nav(props: Props) {
  const location: any = useLocation();
  const [state, setState] = useState({ breadcrumbs: {}, identifier: "", showNextSteps: true });
  let steps1Titles = [];
  let steps2Titles = [];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  let progressPercent1 = [];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  let progressPercent2 = [];
  const host = window.location.origin;

  useEffect(() => {
    emitter.on("progress", ({ breadcrumbs, identifier, showNextSteps }) => {
      showNextSteps = showNextSteps !== undefined ? showNextSteps : true
      setState({ breadcrumbs, identifier, showNextSteps });
    });
  }, []);

  function prepareSteps(
    step: any,
    state: { breadcrumbs: {}; identifier: string },
    stepIdentified: boolean,
    progressPercent?: any[],
  ) {
    const innerSteps = step.steps;
    const progressStep = Math.floor(100 / innerSteps.length);
    const stepIndex = innerSteps.findIndex((obj) => obj === state.identifier);

    if (stepIndex === -1) {
      if (stepIdentified) {
        progressPercent = [...progressPercent, 0];
      } else {
        progressPercent = [...progressPercent, 100];
      }
    } else {
      stepIdentified = true;
      if (stepIndex === innerSteps.length - 1) {
        progressPercent = [...progressPercent, 100];
      } else {
        progressPercent = [
          ...progressPercent,
          progressStep * (stepIndex + 1),
        ];
      }
    }
    return { stepIdentified, progressPercent };
  }

  if (state.breadcrumbs && state.breadcrumbs['application_workflow'] && state.identifier) {
    const steps1 = state.breadcrumbs["application_workflow"];
    const steps2 = state.breadcrumbs["next_steps"];

    let stepIdentified = false;
    steps1.forEach((step: any) => {
      steps1Titles = [...steps1Titles, step.title];

      ({ stepIdentified, progressPercent: progressPercent1 } = prepareSteps(
        step,
        state,
        stepIdentified,
        progressPercent1
      ));
    });

    if (state.showNextSteps) {
      steps2.forEach((step: any) => {
        steps2Titles = [...steps2Titles, step.title];
        ({ stepIdentified, progressPercent: progressPercent2 } = prepareSteps(
          step,
          state,
          stepIdentified,
          progressPercent2
        ));
      });
    }
  }

  useEffect(() => {
    if (progressPercent1[2] !== 0) {
      moveScrollToLeft();
    }
  }, [progressPercent1, progressPercent2])

  const moveScrollToLeft = () => {
    var elmnt = document.getElementById("next-step-div");
    elmnt.scrollIntoView({ behavior: "smooth" });
  }

  const showCardPlaceholder = () => {
    return (location.pathname === "/apply-card/sign-in" && (window.innerWidth > 768));
  }

  return (
    <div id="sidebar" className={`nav-container md:min-h-screen lg:w-1/5 md:right-0 lg:pl-10 xl:pl-9 2xl:pl-12 bg-white md:px-10 pt-8 md:w-1/5 x-sm:flex sm:flex-col x-sm:flex-col md:flex-col sm:align-middle x-sm:align-middle md:space-x-0 x-sm:items-center font-sidebar leading-4 tracking-wide uppercase whitespace-nowrap flex nav-${props.partnerName}`}>
      {showCardPlaceholder() && <div className="flex flex-col justify-center min-h-full x-sm:hidden items-center">
        <img className='max-w-full lg:mt-10 md:mt-10 nav-logo' src={`${IMAGE_PREFIX}/${window['appConfig'].PARTNER_NAME === "sw" ? 'logo-dark.png' : 'logo.png'}`} alt='Logo' />
        {(window['appConfig'].PARTNER_NAME !== "blockfi") && (<div className="text-center flex flex-col h-full items-center">
          <img className='max-w-full lg:mt-32 md:mt-32' src={`${IMAGE_PREFIX}/CardPlaceholder.png`} alt='Card' data-testid="nav_card_placeholder"/>
          <p className="text-base whitespace-normal normal-case lg:mt-12 md:mt-12" data-testid="phone_screen_card_text">Continue with your phone number and email verification to sign in to your account.</p>
        </div>)}
         {(window['appConfig'].PARTNER_NAME === "blockfi") && (<div className="text-center flex flex-col h-full justify-end items-center">
          <img className='w-3/5 lg:mb-10 md:mb-10' src={`${IMAGE_PREFIX}/evolve-logo.png`} alt="evolve-logo" />
        </div>)}
      </div>}
      <div className={`xl:w-1/6 2xl:w-1/6 2xl:fixed lg:fixed lg:pr-7 xl:pr-7 2xl:pr-7 x-sm:w-screen xl:fixed ${!showCardPlaceholder() ? 'visible' : 'invisible'} ${props.partnerName === 'customers_bank' ? '' : `nav-${props.partnerName}`}`}>
        <a href={host} target="_blank" rel="noreferrer" className="text-center">
          <Logo />
        </a>
        <div className=" x-sm:w-full md:w-auto flex flex-1 md:flex-col flex-nowrap overflow-x-hidden">
          <div className="x-sm:flex x-sm:flex-nowrap x-sm:mt-6 mt-10">
            {steps1Titles.map((title, index) => {
              return (
                <div className="x-sm:mb-0 mb-4 py-2 x-sm:mx-3 relative" key={index}>
                  <span className="progress-line x-sm:bottom-3"></span>
                  <span
                    className="progress-line-over x-sm:bottom-3"
                    style={{ width: `${progressPercent1[index]}%` }}
                  ></span>
                  <p className="mt-3 x-sm:pb-3 x-sm:mt-0 nav-text">{title}</p>
                </div>
              );
            })}
          </div>

          <div className="x-sm:flex x-sm:flex-nowrap x-sm:mt-6" id="next-step-div">
            {steps2Titles.map((title, index) => {
              return (
                <div
                  className={`${index === 0 ? "x-sm:mt-0 mt-10" : ""
                    } x-sm:mb-0  mb-5 py-2  x-sm:mx-3 relative`}
                  key={index}
                >
                  <span className="progress-line x-sm:bottom-3"></span>
                  <span
                    className="progress-line-over x-sm:bottom-3"
                    style={{ width: `${progressPercent2[index]}%` }}
                  ></span>
                  <p className="mt-3 x-sm:pb-3 x-sm:mt-0 nav-text">{title}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Nav;
