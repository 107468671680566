function loadTheme(context) {
  //TODO: Change submit button color according to color.

  document.body.style.setProperty("--text-h1", "40px");
  document.body.style.setProperty("--opacity-2", "0.2");

  document.body.style.setProperty("--opacity-4", "0.4");
  document.body.style.setProperty("--text-h1", "40px");
  document.body.style.setProperty("--text-h1-sm", "30px");
  document.body.style.setProperty("--text-h1-lh", "46.6px");
  document.body.style.setProperty("--text-h1-lh-sm", "32px");
  document.body.style.setProperty("--text-size-br1", "10.3051px");
  document.body.style.setProperty("--text-size-t1", "26px");
  document.body.style.setProperty("--text-size-t1-sm", "24px");
  document.body.style.setProperty("--text-small-btn-sm", "16px");
  document.body.style.setProperty("--text-large-btn-sm", "20px");
  document.body.style.setProperty("--text-size-b10", "13px");
  document.body.style.setProperty("--text-size-bn4", "16px");
  document.body.style.setProperty("--theme-error", "#7893FF");
  document.body.style.setProperty("--theme-dashboard-content", "#231841");
  document.body.style.setProperty("--theme-dashboard-t1", "#112949");
  document.body.style.setProperty("--slider-bg", "white");
  document.body.style.setProperty("--slider-off", 'rgb(255, 255, 255, 0.2)');
  document.body.style.setProperty("--theme-cardaction-error", "#FF5D5D");
  document.body.style.setProperty("--text-bold", "none");
  document.body.style.setProperty("--theme-autopay_btn_bg_color", "#FFFFFF");
  document.body.style.setProperty("--theme-autopay_btn_text_color", "#000000");
  document.body.style.setProperty("--theme-typehead-menu-bg", "rgb(255, 255, 255)");
  document.body.style.setProperty("--sub-text-color", "#000000");
  document.body.style.setProperty("--text2", "13px");

  document.body.style.setProperty("--theme-card-active-background", "#fff");
  document.body.style.setProperty("--theme-card-active-color", "#000000");
  document.body.style.setProperty("--app-banner-bg-color", "#328DFD");
  document.body.style.setProperty("--app-banner-bg-br1-color", "#FF5D5D");
  document.body.style.setProperty("--app-banner-btn-t1-bg-color", "#FFFFFF");
  document.body.style.setProperty("--app-banner-btn-t1-color", "#000000");
  document.body.style.setProperty("--app-banner-title-color", "#FFFFFF");
  document.body.style.setProperty("--app-banner-title-t1-color", "#1E1F26");
  document.body.style.setProperty("--app-banner-title-fw", "400");
  document.body.style.setProperty("--app-banner-sub-title-color", "#FFFFFF");
  document.body.style.setProperty("--btn-outline-border-color", "#FF5D5D");
  document.body.style.setProperty("--theme-text-small-btn_font_family", "sf-ui-display");
  document.body.style.setProperty("--input-text-border_radius", "0px");




  if (context === "deserve") {
    document.body.style.setProperty("--brand-br1-color", "#BC9966");
    document.body.style.setProperty("--primary-font", "gt-alpina");
    document.body.style.setProperty("--secondary-font", "sf-ui-text");
    document.body.style.setProperty("--theme-input_label_font_family", "sf-ui-text");
    document.body.style.setProperty("--theme-input_font_family", "sf-ui-text");
    document.body.style.setProperty("--theme-color", "#BC9966");
    document.body.style.setProperty("--progress-line-over-color", "#BC9966");
    document.body.style.setProperty("--text-color", "#1A1717");
    document.body.style.setProperty("--text-color_typehead-menu-item", "#1A1717");
    document.body.style.setProperty("--text-t1-size", "20px");
    document.body.style.setProperty("--text2-color", "#534B4B");
    document.body.style.setProperty("--text2", "13px");
    document.body.style.setProperty("--theme-background", "linear-gradient(243.01deg, #F3E0C9 100%, #FCEEDF 100%)");
    document.body.style.setProperty("--theme-background-dark", "#FF512E");
    document.body.style.setProperty("--theme-background-image", "none");
    document.body.style.setProperty("--theme-color-rgb", "188, 153, 102");
    document.body.style.setProperty("--theme-error", "#7893FF");
    document.body.style.setProperty("--theme-approval-text", "#FDF0E0");
    document.body.style.setProperty("--theme-checkbox-background", "#FF512E");
    document.body.style.setProperty("--theme-radio-checkbox-background", "#FF512E");
    document.body.style.setProperty("--theme-card-background", "#ff745470");
    document.body.style.setProperty("--theme-cardaction-error", "#ff512e");
    document.body.style.setProperty("--theme-card-text", "#FFFFFF");
    document.body.style.setProperty("--theme-popup-text-bg-color", "rgba(188, 153, 102, 0.16)");
    document.body.style.setProperty("--theme-popup-icon-bg-color", "#FAF7F7");
    // New sign in button style vars
    document.body.style.setProperty("--theme-signin_apply_btn_bg_color", "#000000");
    document.body.style.setProperty("--theme-signin_apply_btn_text_color", "#ffffff");
    document.body.style.setProperty("--theme-signin_btn_border_color", "transparent");
    document.body.style.setProperty("--theme-signin_btn_bg_color", "#ffffff");
    document.body.style.setProperty("--theme-signin_btn_text_color", "#000000");
    document.body.style.setProperty("--theme-signin_btn_devider_color", "#ffffff");
    document.body.style.setProperty("--theme-devider_opacity", "0.5");
    document.body.style.setProperty("--theme-signin_diff_option_color", "#ffffff");

    // New theme variables body-b7
    document.body.style.setProperty("--text-b7-font_family", "sf-ui-text");
    document.body.style.setProperty("--text-b7-font_size", "13px");
    document.body.style.setProperty("--text-b7-font_style", "normal");
    document.body.style.setProperty("--text-b7-font_weight", "350");
    document.body.style.setProperty("--text-b7-line_height", "normal");
    document.body.style.setProperty("--text-b7-letter_spacing", "-0.3px");
    document.body.style.setProperty("--text-b7-color", "#FFF");

    document.body.style.setProperty("--text-b5-font_family", "sf-ui-text");
    document.body.style.setProperty("--text-b5-font_size", "16px");
    document.body.style.setProperty("--text-b5-font_weight", "350");
    document.body.style.setProperty("--text-b5-line_height", "20px");
    document.body.style.setProperty("--text-b5-letter_spacing", "-0.3px");
    document.body.style.setProperty("--text-b5-color", "#FFF");

  } else if (context === "blockfi") {
    document.body.style.setProperty("--brand-br1-color", "#FF6230");
    document.body.style.setProperty("--primary-font", "suisseintl");
    document.body.style.setProperty("--secondary-font", "suisseintl");
    document.body.style.setProperty("--theme-input_label_font_family", "suisseintl");
    document.body.style.setProperty("--theme-input_font_family", "suisseintl");
    document.body.style.setProperty("--theme-color", "#FF6230");
    document.body.style.setProperty("--progress-line-over-color", "#FF6230");
    document.body.style.setProperty("--text-color", "#000000");
    document.body.style.setProperty("--text-color_typehead-menu-item", "#000000");
    // document.body.style.setProperty("--text2-color", "#534B4B");
    document.body.style.setProperty("--theme-background", "#F9F9F9");
    document.body.style.setProperty("--theme-background-dark", "#000000");
    document.body.style.setProperty("--theme-background-image", "none");
    document.body.style.setProperty("--theme-color-rgb", "255, 98, 48");
    document.body.style.setProperty("--theme-error", "#FF6230");
    document.body.style.setProperty("--theme-approval-text", "#FDF0E0");
    document.body.style.setProperty("--theme-checkbox-background", "#FF6230");
    document.body.style.setProperty("--theme-radio-checkbox-background", "#FF6230");
    document.body.style.setProperty("--theme-card-background", "#FFFFFF");
    document.body.style.setProperty("--theme-cardaction-error", "#000000");
    document.body.style.setProperty("--theme-card-active-background", "#000000");
    document.body.style.setProperty("--theme-card-active-color", "#FFFFFF");
    document.body.style.setProperty("--theme-card-text", "#1E1F26");
    document.body.style.setProperty("--theme-payment-success-block-background", "#FF6230");
    // New sign in button style vars
    document.body.style.setProperty("--theme-autopay_btn_bg_color", "#FF6230");
    document.body.style.setProperty("--theme-autopay_btn_text_color", "#000000");
    document.body.style.setProperty("--theme-signin_apply_btn_bg_color", "#000000");
    document.body.style.setProperty("--theme-signin_apply_btn_text_color", "#ffffff");
    document.body.style.setProperty("--theme-signin_btn_border_color", "transparent");
    document.body.style.setProperty("--theme-signin_btn_bg_color", "#FF6230");
    document.body.style.setProperty("--theme-signin_btn_text_color", "#ffffff");
    document.body.style.setProperty("--theme-signin_btn_devider_color", "#1A1717");
    document.body.style.setProperty("--theme-devider_opacity", "0.5");
    document.body.style.setProperty("--theme-signin_diff_option_color", "#040D14");
    document.body.style.setProperty("--slider-bg", "#000000");
    document.body.style.setProperty("--slider-off", "rgba(0, 0, 0, 0.2)");

  } else if (context === "sw") {
    document.body.style.setProperty("--brand-br1-color", "#F49C92");
    document.body.style.setProperty("--primary-font", "europa");
    document.body.style.setProperty("--secondary-font", "europa");
    document.body.style.setProperty("--theme-input_label_font_family", "europa");
    document.body.style.setProperty("--theme-input_font_family", "europa");
    document.body.style.setProperty("--theme-color-rgb", "244, 156, 146");
    document.body.style.setProperty("--theme-color", "#F49C92");
    document.body.style.setProperty("--progress-line-over-color", "#F49C92");
    document.body.style.setProperty("--text-color", "#112949");
    document.body.style.setProperty("--text-t1-color", "#112949");
    document.body.style.setProperty("--text-color_typehead-menu-item", "#112949");
    document.body.style.setProperty("--theme-background", "#FAEEEB");
    document.body.style.setProperty("--theme-background-dark", "#112949");
    document.body.style.setProperty("--theme-background-image", "none");
    document.body.style.setProperty("--theme-approval-text", "#FFFFFF");
    document.body.style.setProperty("--theme-checkbox-background", "#112949");
    document.body.style.setProperty("--theme-radio-checkbox-background", "#112949");
    document.body.style.setProperty("--theme-card-background", "#6187ba60");
    document.body.style.setProperty("--theme-card-text", "#FFFFFF");
    document.body.style.setProperty("--theme-subtitle-bg-color", "rgba(244, 156, 146, 0.16)");
    document.body.style.setProperty("--theme-cardaction-error", "#F49C92");
    document.body.style.setProperty("--theme-popup-text-bg-color", "rgba(244, 156, 146, 0.16)");
    document.body.style.setProperty("--theme-popup-icon-bg-color", "#FAF7F7");
    // New sign in button style vars
    document.body.style.setProperty("--theme-signin_apply_btn_bg_color", "rgba(244, 156, 146, 1)");
    document.body.style.setProperty("--theme-signin_apply_btn_text_color", "#ffffff");
    document.body.style.setProperty("--theme-signin_btn_bg_color", "rgba(244, 156, 146, .15)");
    document.body.style.setProperty("--theme-signin_btn_border_color", "rgba(244, 156, 146, .15)");
    document.body.style.setProperty("--theme-signin_btn_text_color", "#F49C92");
    document.body.style.setProperty("--theme-signin_btn_devider_color", "rgba(255, 255, 255, .15)");
    document.body.style.setProperty("--theme-devider_opacity", "0.5");
    document.body.style.setProperty("--theme-signin_diff_option_color", "rgba(255, 255, 255, 1)");
  } else if (context === "earnest") {
    document.body.style.setProperty("--brand-br1-color", "#21CF93");
    document.body.style.setProperty("--primary-font", "circular");
    document.body.style.setProperty("--secondary-font", "sf-ui-text");
    document.body.style.setProperty("--theme-input_label_font_family", "sf-ui-text");
    document.body.style.setProperty("--theme-input_font_family", "sf-ui-text");
    document.body.style.setProperty("--theme-color", "#21CF93");
    document.body.style.setProperty("--progress-line-over-color", "#21CF93");
    document.body.style.setProperty("--theme-color-rgb", "33, 207, 147");
    document.body.style.setProperty("--text-color", "#4E1BB4");
    document.body.style.setProperty("--text-t2-color", "#231841");
    document.body.style.setProperty("--text-color_typehead-menu-item", "#4E1BB4");
    document.body.style.setProperty("--theme-background", "#F8F6FA");
    document.body.style.setProperty("--theme-background-dark", "#4E1BB4");
    document.body.style.setProperty("--theme-background-image", "none");
    document.body.style.setProperty("--theme-approval-text", "#FFFFFF");
    document.body.style.setProperty("--theme-checkbox-background", "#4E1BB4");
    document.body.style.setProperty("--theme-radio-checkbox-background", "#4E1BB4");
    document.body.style.setProperty("--theme-card-background", "#af9dd440");
    document.body.style.setProperty("--theme-card-text", "#FFFFFF");
    document.body.style.setProperty("--theme-subtitle-bg-color", "rgba(33, 207, 147, 0.16)");
    document.body.style.setProperty("--theme-cardaction-error", "#21CF93");
    document.body.style.setProperty("--theme-popup-text-bg-color", "rgba(33, 207, 147, 0.16)");
    document.body.style.setProperty("--theme-popup-icon-bg-color", "#FAF7F7");
    // New sign in button style vars
    document.body.style.setProperty("--theme-signin_apply_btn_bg_color", "rgba(33, 207, 147, 1)");
    document.body.style.setProperty("--theme-signin_apply_btn_text_color", "#ffffff");
    document.body.style.setProperty("--theme-signin_btn_bg_color", "rgba(33, 207, 147, 1)");
    document.body.style.setProperty("--theme-signin_btn_border_color", "rgba(33, 207, 147, .15)");
    document.body.style.setProperty("--theme-signin_btn_text_color", "#ffffff");
    document.body.style.setProperty("--theme-signin_btn_devider_color", "rgba(78, 27, 180, 1)");
    document.body.style.setProperty("--theme-devider_opacity", "0.5");
    document.body.style.setProperty("--theme-signin_diff_option_color", "rgba(78, 27, 180, 1)");
  } else if (context === "oppfi") {
    document.body.style.setProperty("--brand-br1-color", "#0528A0");
    document.body.style.setProperty("--primary-font", "Arimo");
    document.body.style.setProperty("--secondary-font", "Arimo");
    document.body.style.setProperty("--theme-input_label_font_family", "Arimo");
    document.body.style.setProperty("--theme-input_font_family", "Arimo");
    document.body.style.setProperty("--theme-color", "#0528A0");
    document.body.style.setProperty("--progress-line-over-color", "#0528A0");
    document.body.style.setProperty("--text-color", "#1A1717");
    document.body.style.setProperty("--text-t1-color", "#0F1515");
    document.body.style.setProperty("--text-color_typehead-menu-item", "#1A1717");
    document.body.style.setProperty("--theme-background", "#F3F5FB");
    document.body.style.setProperty("--theme-background-dark", "#0528A0");
    document.body.style.setProperty("--theme-background-image", "none");
    document.body.style.setProperty("--theme-color-rgb", "5, 40, 160");
    document.body.style.setProperty("--theme-approval-text", "#FFFFFF");
    document.body.style.setProperty("--theme-border-color", "#000");
    document.body.style.setProperty("--theme-checkbox-background", "#0528A0");
    document.body.style.setProperty("--theme-radio-checkbox-background", "#0528A0");
    document.body.style.setProperty("--theme-card-background", "#849be860");
    document.body.style.setProperty("--theme-card-text", "#FFFFFF");
    document.body.style.setProperty("--text-bold", "700");
    // New sign in button style vars
    document.body.style.setProperty("--theme-signin_apply_btn_bg_color", "rgba(5, 40, 160, 1)");
    document.body.style.setProperty("--theme-signin_apply_btn_text_color", "#ffffff");
    document.body.style.setProperty("--theme-signin_btn_bg_color", "rgba(5, 40, 160, .15)");
    document.body.style.setProperty("--theme-signin_btn_border_color", "rgba(5, 40, 160, .15)");
    document.body.style.setProperty("--theme-signin_btn_text_color", "rgba(5, 40, 160, 1)");
    document.body.style.setProperty("--theme-signin_btn_devider_color", "rgba(26, 23, 23, 1)");
    document.body.style.setProperty("--theme-devider_opacity", "0.5");
    document.body.style.setProperty("--theme-signin_diff_option_color", "rgba(26, 23, 23, 1)");
  } else if (context === "krowdfit") {
    document.body.style.setProperty("--brand-br1-color", "#EB6E1E");
    document.body.style.setProperty("--primary-font", "Aktiv-Grotesk-Light");
    document.body.style.setProperty("--secondary-font", "Aktiv-Grotesk-Regular");
    document.body.style.setProperty("--theme-input_label_font_family", "Aktiv-Grotesk-Regular");
    document.body.style.setProperty("--theme-input_font_family", "Aktiv-Grotesk-Regular");
    document.body.style.setProperty("--theme-color-rgb", "235, 110, 30");
    document.body.style.setProperty("--theme-color", "#EB6E1E");
    document.body.style.setProperty("--progress-line-over-color", "#EB6E1E");
    document.body.style.setProperty("--text-color", "#4C4C4C");
    document.body.style.setProperty("--text-t1-color", "#000000");
    document.body.style.setProperty("--text-color_typehead-menu-item", "#4C4C4C");
    document.body.style.setProperty("--letter-spacing", "-0.3px");
    document.body.style.setProperty("--theme-background", "##EB6E1E");
    document.body.style.setProperty("--theme-background-dark", "#EB6E1E");
    document.body.style.setProperty("--theme-background-image", "none");
    document.body.style.setProperty("--theme-approval-text", "#FFFFFF");
    document.body.style.setProperty("--theme-checkbox-background", "#EB6E1E");
    document.body.style.setProperty("--theme-radio-checkbox-background", "#EB6E1E");
    document.body.style.setProperty("--theme-card-background", "#3333332b");
    document.body.style.setProperty("--theme-card-text", "#FFFFFF");
    document.body.style.setProperty("--theme-subtitle-bg-color", "rgba(235, 110, 30, 0.16)");
    document.body.style.setProperty("--theme-cardaction-error", "#EB6E1E");
    document.body.style.setProperty("--theme-popup-text-bg-color", "rgba(235, 110, 30, 0.16)");
    document.body.style.setProperty("--theme-popup-icon-bg-color", "#FAF7F7");
    // New sign in button style vars
    document.body.style.setProperty("--theme-signin_apply_btn_bg_color", "rgba(235, 110, 30, 1)");
    document.body.style.setProperty("--theme-signin_apply_btn_text_color", "#ffffff");
    document.body.style.setProperty("--theme-signin_btn_bg_color", "rgba(235, 110, 30, .15)");
    document.body.style.setProperty("--theme-signin_btn_border_color", "rgb(235, 110, 30, .15)");
    document.body.style.setProperty("--theme-signin_btn_text_color", "rgba(235, 110, 30, 1)");
    document.body.style.setProperty("--theme-signin_btn_devider_color", "rgba(4, 13, 20, 1)");
    document.body.style.setProperty("--theme-devider_opacity", "0.5");
    document.body.style.setProperty("--theme-signin_diff_option_color", "rgba(4, 13, 20, 1)");

    // New theme variables body-b7
    document.body.style.setProperty("--text-b7-font_family", "Aktiv-Grotesk-Regular");
    document.body.style.setProperty("--text-b7-font_size", "13px");
    document.body.style.setProperty("--text-b7-font_style", "normal");
    document.body.style.setProperty("--text-b7-font_weight", "350");
    document.body.style.setProperty("--text-b7-line_height", "normal");
    document.body.style.setProperty("--text-b7-letter_spacing", "-0.3px");
    document.body.style.setProperty("--text-b7-color", "#FFF");
  } else if (context === "customers_bank") {
    document.body.style.setProperty("--brand-br1-color", "#009DFF");
    document.body.style.setProperty("--primary-font", "Poppins-Semibold");
    document.body.style.setProperty("--secondary-font", "Poppins-Regular");
    document.body.style.setProperty("--theme-input_label_font_family", "Poppins-Regular");
    document.body.style.setProperty("--theme-input_font_family", "Poppins-Regular");
    document.body.style.setProperty("--theme-color-rgb", "0, 157, 255");
    document.body.style.setProperty("--theme-color", "#009DFF");
    document.body.style.setProperty("--text-h1-sm", "26px");
    document.body.style.setProperty("--progress-line-over-color", "#009DFF");
    document.body.style.setProperty("--text-color", "#011936");
    document.body.style.setProperty("--text-color_typehead-menu-item", "#011936");
    document.body.style.setProperty("--theme-error", "#009DFF");
    document.body.style.setProperty("--letter-spacing", "-0.3px");
    document.body.style.setProperty("--theme-background", "#FFFFFF");
    document.body.style.setProperty("--theme-background-dark", "#011936");
    document.body.style.setProperty("--theme-background-image", "none");
    document.body.style.setProperty("--theme-approval-text", "#FFFFFF");
    document.body.style.setProperty("--theme-checkbox-background", "#009DFF");
    document.body.style.setProperty("--theme-radio-checkbox-background", "#009DFF");
    document.body.style.setProperty("--theme-card-background", "#FFFFFF");
    document.body.style.setProperty("--theme-card-text", "#000000");
    document.body.style.setProperty("--slider-bg", "#E5E5E5");
    document.body.style.setProperty("--slider-off", '#E5E5E5');
    document.body.style.setProperty("--text-bold", "600");
    document.body.style.setProperty("--theme-payment-success-block-background", "#01264A");
    document.body.style.setProperty("--theme-subtitle-bg-color", "rgba(0, 157, 255, 0.16)");
    document.body.style.setProperty("--theme-cardaction-error", "#009DFF");
    document.body.style.setProperty("--theme-popup-text-bg-color", "rgba(0, 157, 255, 0.16)");
    document.body.style.setProperty("--theme-popup-icon-bg-color", "#FAF7F7");
    // New sign in button style vars
    document.body.style.setProperty("--theme-signin_apply_btn_bg_color", "#009DFF");
    document.body.style.setProperty("--theme-autopay_btn_bg_color", "#009DFF");
    document.body.style.setProperty("--theme-autopay_btn_text_color", "#FFFFFF");
    document.body.style.setProperty("--theme-signin_apply_btn_text_color", "#ffffff");
    document.body.style.setProperty("--theme-signin_btn_bg_color", "rgba(0, 157, 255, .15)");
    document.body.style.setProperty("--theme-signin_btn_border_color", "rgba(0, 157, 255, .15)");
    document.body.style.setProperty("--theme-signin_btn_text_color", "#009DFF");
    document.body.style.setProperty("--theme-signin_btn_devider_color", "#1A1717");
    document.body.style.setProperty("--theme-devider_opacity", "0.5");
    document.body.style.setProperty("--theme-signin_diff_option_color", "#040D14");

    // New theme variables body-b7
    document.body.style.setProperty("--text-b7-font_family", "Poppins-Regular");
    document.body.style.setProperty("--text-b7-font_size", "13px");
    document.body.style.setProperty("--text-b7-font_style", "normal");
    document.body.style.setProperty("--text-b7-font_weight", "350");
    document.body.style.setProperty("--text-b7-line_height", "normal");
    document.body.style.setProperty("--text-b7-letter_spacing", "-0.3px");
    document.body.style.setProperty("--text-b7-color", "#000000");
  } else if (context === "amc") {
    document.body.style.setProperty("--brand-br1-color", "#FF2532");
    document.body.style.setProperty("--text-h1", "38px");
    document.body.style.setProperty("--primary-font", "Gordita");
    document.body.style.setProperty("--secondary-font", "Gordita");
    document.body.style.setProperty("--theme-input_label_font_family", "Gordita");
    document.body.style.setProperty("--theme-input_font_family", "Gordita");
    document.body.style.setProperty("--theme-background", "#442D30");

    document.body.style.setProperty("--text-t1-size", "30px");
    document.body.style.setProperty("--text-t1-weight", "700");
    document.body.style.setProperty("--text-t1-color", "#d4d4d4");
    document.body.style.setProperty("--text-t1-font", "Gordita");
    document.body.style.setProperty("--text-t1-lh", "31px");

    document.body.style.setProperty("--text-t2-size", "18px");
    document.body.style.setProperty("--text-t2-weight", "400");
    document.body.style.setProperty("--text-t2-color", "#B3B3B3");
    document.body.style.setProperty("--text-t2-font", "sf-ui-display");
    document.body.style.setProperty("--text-t2-lh", "21px");

    document.body.style.setProperty("--text-t3-size", "10px");
    document.body.style.setProperty("--text-t3-weight", "400");
    document.body.style.setProperty("--text-t3-color", "#FFFFFF");
    document.body.style.setProperty("--text-t3-font", "sf-ui-display");
    document.body.style.setProperty("--text-t3-lh", "12px");
    document.body.style.setProperty("--text-t3-op", "0.6");

    document.body.style.setProperty("--theme-cardaction-error", "#FFFFFF");

    document.body.style.setProperty("--theme-color-rgb", "239, 47, 58");
    document.body.style.setProperty("--theme-color", "#FF2532");
    document.body.style.setProperty("--progress-line-over-color", "#FF2532");
    document.body.style.setProperty("--text-color", "#FFFFFF");
    document.body.style.setProperty("--text2-color", "#B3B3B3");
    document.body.style.setProperty("--text-color_typehead-menu-item", "#000000");
    document.body.style.setProperty("--theme-error", "#D2353F");
    document.body.style.setProperty("--letter-spacing", "-0.3px");
    document.body.style.setProperty("--theme-background-dark", "#D2353F");
    document.body.style.setProperty("--theme-background-image", "url(https://dfcweb-assets-static.deserve.com/images/hotlink-ok/amc/amc-bg-image.png)");
    document.body.style.setProperty("--theme-approval-text", "#FFFFFF");
    document.body.style.setProperty("--theme-checkbox-background", "#fff");
    document.body.style.setProperty("--theme-radio-checkbox-background", "#FF2532");
    document.body.style.setProperty("--theme-card-background", "#121010");
    document.body.style.setProperty("--theme-card-text", "#d4d4d4");
    document.body.style.setProperty("--slider-bg", "#E5E5E5");
    document.body.style.setProperty("--slider-off", '#211E1E');
    document.body.style.setProperty("--theme-typehead-menu-bg", "rgb(255, 255, 255)");
    document.body.style.setProperty("--sub-text-color", "#B3B3B3");
    document.body.style.setProperty("--theme-card-active-background", "#D2353F");
    document.body.style.setProperty("--theme-card-active-color", "#FFFFFF");
    // document.body.style.setProperty("--theme-dashboard-content", "#231841");
    document.body.style.setProperty("--theme-dashboard-t1", "#B3B3B3");
    document.body.style.setProperty("--theme-payment-success-block-background", "#272323");
    document.body.style.setProperty("--theme-subtitle-bg-color", "rgba(255, 37, 50, 0.16)");
    document.body.style.setProperty("--theme-popup-text-bg-color", "rgba(255, 37, 50, 0.16)");
    document.body.style.setProperty("--theme-popup-icon-bg-color", "#D4D4D4");
    // New sign in button style vars
    document.body.style.setProperty("--theme-signin_apply_btn_bg_color", "#FF2532");
    document.body.style.setProperty("--theme-autopay_btn_bg_color", "#D2353F");
    document.body.style.setProperty("--theme-autopay_btn_text_color", "#FFFFFF");
    document.body.style.setProperty("--theme-signin_apply_btn_text_color", "#ffffff");
    document.body.style.setProperty("--theme-signin_btn_bg_color", "rgba(255, 37, 50, .15)");
    document.body.style.setProperty("--theme-signin_btn_border_color", "#FFFFFF");
    document.body.style.setProperty("--theme-signin_btn_text_color", "#FFFFFF");
    document.body.style.setProperty("--theme-signin_btn_devider_color", "#B3B3B3");
    document.body.style.setProperty("--theme-devider_opacity", "0.5");
    document.body.style.setProperty("--theme-signin_diff_option_color", "#B3B3B3");

    // New theme variables body-b7
    document.body.style.setProperty("--text-b7-font_family", "Gordita");
    document.body.style.setProperty("--text-b7-font_size", "13px");
    document.body.style.setProperty("--text-b7-font_style", "normal");
    document.body.style.setProperty("--text-b7-font_weight", "350");
    document.body.style.setProperty("--text-b7-line_height", "normal");
    document.body.style.setProperty("--text-b7-letter_spacing", "-0.3px");
    document.body.style.setProperty("--text-b7-color", "#d4d4d4");
  } else if(context === 'slm_ignite') {
    document.body.style.setProperty("--brand-br1-color", "#87A945");
    document.body.style.setProperty("--primary-font", "din-pro");
    document.body.style.setProperty("--secondary-font", "avenir-book");
    document.body.style.setProperty("--theme-input_label_font_family", "avenir-medium");
    document.body.style.setProperty("--theme-input_font_family", "avenir-book");
    document.body.style.setProperty("--theme-color-rgb", "135, 169, 69");
    document.body.style.setProperty("--theme-color", "#87A945");
    document.body.style.setProperty("--text-h1-sm", "26px");
    document.body.style.setProperty("--progress-line-over-color", "#87A945");
    document.body.style.setProperty("--text-color", "#57595E");
    document.body.style.setProperty("--text-color_typehead-menu-item", "#57595E");
    document.body.style.setProperty("--text2-color", "#FFFFFF");
    document.body.style.setProperty("--theme-error", "#57595E");
    document.body.style.setProperty("--letter-spacing", "-0.3px");
    document.body.style.setProperty("--theme-background", "#F7F7F7");
    document.body.style.setProperty("--theme-background-dark", "#0D0C0C");
    document.body.style.setProperty("--theme-background-image", "none");
    document.body.style.setProperty("--theme-approval-text", "#FFFFFF");
    document.body.style.setProperty("--theme-checkbox-background", "#87A945");
    document.body.style.setProperty("--theme-radio-checkbox-background", "#87A945");
    document.body.style.setProperty("--theme-card-background", "#FFFFFF");
    document.body.style.setProperty("--theme-card-text", "#000000");
    document.body.style.setProperty("--slider-bg", "#E5E5E5");
    document.body.style.setProperty("--slider-off", '#E5E5E5');
    document.body.style.setProperty("--text-bold", "600");
    document.body.style.setProperty("--theme-payment-success-block-background", "#01264A");
    document.body.style.setProperty("--theme-subtitle-bg-color", "rgba(0, 157, 255, 0.16)");
    document.body.style.setProperty("--theme-cardaction-error", "#87A945");
    document.body.style.setProperty("--theme-popup-text-bg-color", "rgba(0, 157, 255, 0.16)");
    document.body.style.setProperty("--theme-popup-icon-bg-color", "#FAF7F7");

    document.body.style.setProperty("--theme-autopay_btn_bg_color", "#87A945");
    document.body.style.setProperty("--theme-autopay_btn_text_color", "#FFFFFF");
    document.body.style.setProperty("--text-t1-color", "#1A1717");

    // New sign in button style vars
    document.body.style.setProperty("--theme-signin_apply_btn_bg_color", "#87A945");
    document.body.style.setProperty("--theme-autopay_btn_bg_color", "#87A945");
    document.body.style.setProperty("--theme-autopay_btn_text_color", "#FFFFFF");
    document.body.style.setProperty("--theme-signin_apply_btn_text_color", "#ffffff");
    document.body.style.setProperty("--theme-signin_btn_bg_color", "#87A945");
    document.body.style.setProperty("--theme-signin_btn_border_color", "#87A945");
    document.body.style.setProperty("--theme-signin_btn_text_color", "#FFFFFF");
    document.body.style.setProperty("--theme-signin_btn_devider_color", "#1A1717");
    document.body.style.setProperty("--theme-devider_opacity", "0.5");
    document.body.style.setProperty("--theme-signin_diff_option_color", "#1A1717");

    // New theme variables text_1_heading
    document.body.style.setProperty("--theme-text_1_h_color", "#221F20");
    document.body.style.setProperty("--theme-text_1_h_font_size", "38px");
    document.body.style.setProperty("--theme-text_1_h_font_style", "normal");
    document.body.style.setProperty("--theme-text_2_h_font_weight", "400");
    document.body.style.setProperty("--theme-text_1_h_leading", "normal");
    document.body.style.setProperty("--theme-text_1_h_font_family", "din-pro");
    document.body.style.setProperty("--theme-text_1_h_letter_spacing", "-0.3px");
    document.body.style.setProperty("--theme-text_1_h_font_feature_settings", "'clig' off, 'liga' off'");

    document.body.style.setProperty("--xsm-theme-text_1_h_font_size", "28px");

    // New theme variables text_1_heading_2
    document.body.style.setProperty("--theme-text_1_h_2_color", "#57595E");
    document.body.style.setProperty("--theme-text_1_h_2_font_size", "18px");
    document.body.style.setProperty("--theme-text_1_h_2_font_style", "normal");
    document.body.style.setProperty("--theme-text_2_h_2_font_weight", "350");
    document.body.style.setProperty("--theme-text_1_h_2_leading", "normal");
    document.body.style.setProperty("--theme-text_1_h_2_font_family", "avenir-book");
    document.body.style.setProperty("--theme-text_1_h_2_letter_spacing", "-0.3px");
    document.body.style.setProperty("--theme-text_1_h_2_font_feature_settings", "'clig' off, 'liga' off'");
    document.body.style.setProperty("--xsm-theme-text_1_h_2_font_size", "16px");


    // New theme variables text_1_heading_3
    document.body.style.setProperty("--theme-text_1_h_3_color", "#534B4B");
    document.body.style.setProperty("--theme-text_1_h_3_font_size", "12px");
    document.body.style.setProperty("--theme-text_1_h_3_font_style", "normal");
    document.body.style.setProperty("--theme-text_2_h_3_font_weight", "400");
    document.body.style.setProperty("--theme-text_1_h_3_leading", "normal");
    document.body.style.setProperty("--theme-text_1_h_3_font_family", "avenir-book");
    document.body.style.setProperty("--theme-text_1_h_3_letter_spacing", "-0.3px");
    document.body.style.setProperty("--theme-text_1_h_3_font_feature_settings", "'clig' off, 'liga' off'");


    // New theme variables text_2_heading
    document.body.style.setProperty("--theme-text_2_h_color", "#57595E");
    document.body.style.setProperty("--theme-text_2_h_font_size", "16px");
    document.body.style.setProperty("--theme-text_2_h_font_style", "normal");
    document.body.style.setProperty("--theme-text_2_h_font_weight", "350");
    document.body.style.setProperty("--theme-text_2_h_leading", "normal");
    document.body.style.setProperty("--theme-text_2_h_font_family", "avenir-book");
    document.body.style.setProperty("--theme-text_2_h_letter_spacing", "-0.3px");
    document.body.style.setProperty("--theme-text_2_h_font_feature_settings", "'clig' off, 'liga' off'");


    // New theme variables brand_btn_void
    document.body.style.setProperty("--theme-brand_btn_void_color", "#87A945");
    document.body.style.setProperty("--theme-brand_btn_void_font_size", "16px");
    document.body.style.setProperty("--theme-brand_btn_void_font_style", "normal");
    document.body.style.setProperty("--theme-brand_btn_void_font_weight", "500");
    document.body.style.setProperty("--theme-brand_btn_void_leading", "normal");
    document.body.style.setProperty("--theme-brand_btn_void_font_family", "avenir-book");
    document.body.style.setProperty("--theme-brand_btn_void_letter_spacing", "-0.3px");
    document.body.style.setProperty("--theme-brand_btn_void_text_center", "center");
    document.body.style.setProperty("--theme-brand_btn_void_font_feature_settings", "'clig' off, 'liga' off'");

    // New theme variables brand_btn_solid
    document.body.style.setProperty("--theme-brand_btn_solid_color", "#FFF");
    document.body.style.setProperty("--theme-brand_btn_solid_bg_color", "#87A945");
    document.body.style.setProperty("--theme-brand_btn_solid_font_size", "16px");
    document.body.style.setProperty("--theme-brand_btn_solid_font_style", "normal");
    document.body.style.setProperty("--theme-brand_btn_solid_font_weight", "500");
    document.body.style.setProperty("--theme-brand_btn_solid_leading", "normal");
    document.body.style.setProperty("--theme-brand_btn_solid_font_family", "avenir-book");
    document.body.style.setProperty("--theme-brand_btn_solid_letter_spacing", "-0.3px");
    document.body.style.setProperty("--theme-brand_btn_solid_text_center", "center");
    document.body.style.setProperty("--theme-brand_btn_solid_font_feature_settings", "'clig' off, 'liga' off'");
    document.body.style.setProperty("--theme-brand_btn_solid_disabled_bg_color", "#87a9459e");
    document.body.style.setProperty("--theme-brand_btn_solid_disabled_color", "#ffffff8c");

    document.body.style.setProperty("--text-t2-color", "#57595E");

    // New theme variables body-b3
    document.body.style.setProperty("--text-b3-font_family", "avenir-book");
    document.body.style.setProperty("--text-b3-font_size", "18px");
    document.body.style.setProperty("--text-b3-font_weight", "350");
    document.body.style.setProperty("--text-b3-letter_spacing", "-0.3px");

    // New theme variables body-b4
    document.body.style.setProperty("--text-b4-font_family", "avenir-book");
    document.body.style.setProperty("--text-b4-font_size", "18px");
    document.body.style.setProperty("--text-b4-font_weight", "500");
    document.body.style.setProperty("--text-b4-letter_spacing", "-0.3px");

    // New theme variables body-b5
    document.body.style.setProperty("--text-b5-font_family", "avenir-book");
    document.body.style.setProperty("--text-b5-font_size", "16px");
    document.body.style.setProperty("--text-b5-font_weight", "350");
    document.body.style.setProperty("--text-b5-line_height", "20px");
    document.body.style.setProperty("--text-b5-letter_spacing", "-0.3px");

    // New theme variables body-b7
    document.body.style.setProperty("--text-b7-font_family", "avenir-book");
    document.body.style.setProperty("--text-b7-font_size", "13px");
    document.body.style.setProperty("--text-b7-font_style", "normal");
    document.body.style.setProperty("--text-b7-font_weight", "350");
    document.body.style.setProperty("--text-b7-line_height", "normal");
    document.body.style.setProperty("--text-b7-letter_spacing", "-0.3px");
    document.body.style.setProperty("--text-b7-color", "#57595E");

    // New theme variables body-b10
    document.body.style.setProperty("--text-b10-font_family", "avenir-book");
    document.body.style.setProperty("--text-b10-font_size", "13px");
    document.body.style.setProperty("--text-b10-font_weight", "350");
    document.body.style.setProperty("--text-b10-letter_spacing", "-0.3px");

    // New theme variables body-b11
    document.body.style.setProperty("--text-b11-font_family", "avenir-book");
    document.body.style.setProperty("--text-b11-font_size", "10px");
    document.body.style.setProperty("--text-b11-font_weight", "350");
    document.body.style.setProperty("--text-b11-letter_spacing", "-0.3px");
    document.body.style.setProperty("--text-b11-line_height", "normal");
    document.body.style.setProperty("--text-b11-color", "#221F20");

    // New theme variables captions-c1
    document.body.style.setProperty("--text-c1-font_family", "avenir-book");
    document.body.style.setProperty("--text-c1-font_size", "10px");
    document.body.style.setProperty("--text-c1-font_weight", "350");
    document.body.style.setProperty("--text-c1-letter_spacing", "1px");

    // New theme variables captions-c2-standard
    document.body.style.setProperty("--text-c2-std-font_family", "sf-ui-text");
    document.body.style.setProperty("--text-c2-std-font_size", "10px");
    document.body.style.setProperty("--text-c2-std-font_weight", "400");
    document.body.style.setProperty("--text-c2-std-line_height", "11.93px");
    document.body.style.setProperty("--text-c2-std-letter_spacing", "1px");

    // New theme variables captions-c6
    document.body.style.setProperty("--text-c6-font_family", "avenir-book");
    document.body.style.setProperty("--text-c6-font_size", "10px");
    document.body.style.setProperty("--text-c6-font_weight", "500");

    // New theme variables headings-h1
    document.body.style.setProperty("--text-h1-font_family", "din-pro");
    document.body.style.setProperty("--text-h1-font_size", "38px");
    document.body.style.setProperty("--text-h1-font_weight", "400");
    document.body.style.setProperty("--text-h1-line_height", "40px");
    document.body.style.setProperty("--text-h1-letter_spacing", "-0.3px");

    // New theme variables headings-h2
    document.body.style.setProperty("--text-h2-font_family", "din-pro");
    document.body.style.setProperty("--text-h2-font_size", "30px");
    document.body.style.setProperty("--text-h2-font_weight", "400");
    document.body.style.setProperty("--text-h2-line_height", "32px");

    // New theme variables headings-h4
    document.body.style.setProperty("--text-h4-font_family", "din-pro");
    document.body.style.setProperty("--text-h4-font_size", "20px");
    document.body.style.setProperty("--text-h4-font_weight", "400");
    document.body.style.setProperty("--text-h4-letter_spacing", "-0.3px");


    // New theme variables headings-h5
    document.body.style.setProperty("--text-h5-font_family", "din-pro");
    document.body.style.setProperty("--text-h5-font_size", "18px");
    document.body.style.setProperty("--text-h5-font_weight", "400");

    // New theme variables numbers-n1
    document.body.style.setProperty("--text-n1-font_family", "din-pro");
    document.body.style.setProperty("--text-n1-font_size", "70px");
    document.body.style.setProperty("--text-n1-font_weight", "400");

    // New theme variables numbers-n3
    document.body.style.setProperty("--text-n3-font_family", "din-pro");
    document.body.style.setProperty("--text-n3-font_size", "50px");
    document.body.style.setProperty("--text-n3-font_weight", "400");
    document.body.style.setProperty("--text-n3-letter_spacing", "-0.3px");

    // New theme variables numbers-n6
    document.body.style.setProperty("--text-n6-font_family", "din-pro");
    document.body.style.setProperty("--text-n6-font_size", "16px");
    document.body.style.setProperty("--text-n6-font_weight", "400");
    document.body.style.setProperty("--text-n6-letter_spacing", "-0.3px");

    // New theme variables button-bn1
    document.body.style.setProperty("--text-bn1-font_family", "avenir-book");
    document.body.style.setProperty("--text-bn1-font_size", "20px");
    document.body.style.setProperty("--text-bn1-font_weight", "500");
    document.body.style.setProperty("--text-bn1-letter_spacing", "-0.4px");

    // New theme variables button-bn3
    document.body.style.setProperty("--text-bn3-font_family", "avenir-book");
    document.body.style.setProperty("--text-bn3-font_size", "16px");
    document.body.style.setProperty("--text-bn3-font_weight", "500");
  } else if(context === 'deserve_edu') {

    document.body.style.setProperty("--brand-br1-color", "#477BE2");
    document.body.style.setProperty("--primary-font", "suisseintl");
    document.body.style.setProperty("--secondary-font", "avenir-book");
    document.body.style.setProperty("--theme-input_label_font_family", "suisseintl");
    document.body.style.setProperty("--theme-input_font_family", "suisseintl");
    document.body.style.setProperty("--theme-color-rgb", "71, 123, 226");
    document.body.style.setProperty("--theme-color", "#477BE2");
    document.body.style.setProperty("--text-h1-sm", "26px");
    document.body.style.setProperty("--progress-line-over-color", "#87A945");
    document.body.style.setProperty("--text-color", "#57595E");
    document.body.style.setProperty("--text-color_typehead-menu-item", "#57595E");
    document.body.style.setProperty("--text2-color", "#FFFFFF");
    document.body.style.setProperty("--theme-error", "#57595E");
    document.body.style.setProperty("--letter-spacing", "-0.3px");
    document.body.style.setProperty("--theme-background", "#F0F1F4");
    document.body.style.setProperty("--theme-background-dark", "#0CB9AE");
    document.body.style.setProperty("--theme-br2", "#19C5BA");
    document.body.style.setProperty("--theme-background-image", "none");
    document.body.style.setProperty("--theme-approval-text", "#FFFFFF");
    document.body.style.setProperty("--theme-checkbox-background", "#477BE2");
    document.body.style.setProperty("--theme-radio-checkbox-background", "#477BE2");
    document.body.style.setProperty("--theme-card-background", "#FFFFFF");
    document.body.style.setProperty("--theme-card-text", "#000000");
    document.body.style.setProperty("--slider-bg", "#E5E5E5");
    document.body.style.setProperty("--slider-off", '#E5E5E5');
    document.body.style.setProperty("--text-bold", "600");
    document.body.style.setProperty("--theme-payment-success-block-background", "#fff");
    document.body.style.setProperty("--theme-subtitle-bg-color", "rgba(0, 157, 255, 0.16)");
    document.body.style.setProperty("--theme-cardaction-error", "#011936");
    document.body.style.setProperty("--theme-popup-text-bg-color", "rgba(0, 157, 255, 0.16)");
    document.body.style.setProperty("--theme-popup-icon-bg-color", "#FAF7F7");
    document.body.style.setProperty("--theme-autopay_btn_bg_color", "#477BE2");
    document.body.style.setProperty("--theme-autopay_btn_text_color", "#FFFFFF");
    document.body.style.setProperty("--text-t1-color", "#2D282E");

    document.body.style.setProperty("--text-t2-font", "suisseintl-light");

    // New sign in button style vars
    // document.body.style.setProperty("--theme-signin_apply_btn_bg_color", "#87A945");
    // document.body.style.setProperty("--theme-autopay_btn_bg_color", "#87A945");
    // document.body.style.setProperty("--theme-autopay_btn_text_color", "#FFFFFF");
    // document.body.style.setProperty("--theme-signin_apply_btn_text_color", "#ffffff");
    // document.body.style.setProperty("--theme-signin_btn_bg_color", "#87A945");
    // document.body.style.setProperty("--theme-signin_btn_border_color", "#87A945");
    // document.body.style.setProperty("--theme-signin_btn_text_color", "#FFFFFF");
    // document.body.style.setProperty("--theme-signin_btn_devider_color", "#1A1717");
    // document.body.style.setProperty("--theme-devider_opacity", "0.5");
    // document.body.style.setProperty("--theme-signin_diff_option_color", "#1A1717");

    // New theme variables text_1_heading
    document.body.style.setProperty("--theme-text_1_h_color", "#221F20");
    document.body.style.setProperty("--theme-text_1_h_font_size", "38px");
    document.body.style.setProperty("--theme-text_1_h_font_style", "normal");
    document.body.style.setProperty("--theme-text_2_h_font_weight", "400");
    document.body.style.setProperty("--theme-text_1_h_leading", "normal");
    document.body.style.setProperty("--theme-text_1_h_font_family", "suisseintl");
    document.body.style.setProperty("--theme-text_1_h_letter_spacing", "-0.3px");
    document.body.style.setProperty("--theme-text_1_h_font_feature_settings", "'clig' off, 'liga' off'");

    document.body.style.setProperty("--xsm-theme-text_1_h_font_size", "28px");

    // New theme variables text_1_heading_2
    document.body.style.setProperty("--theme-text_1_h_2_color", "#57595E");
    document.body.style.setProperty("--theme-text_1_h_2_font_size", "18px");
    document.body.style.setProperty("--theme-text_1_h_2_font_style", "normal");
    document.body.style.setProperty("--theme-text_2_h_2_font_weight", "350");
    document.body.style.setProperty("--theme-text_1_h_2_leading", "normal");
    document.body.style.setProperty("--theme-text_1_h_2_font_family", "suisseintl");
    document.body.style.setProperty("--theme-text_1_h_2_letter_spacing", "-0.3px");
    document.body.style.setProperty("--theme-text_1_h_2_font_feature_settings", "'clig' off, 'liga' off'");
    document.body.style.setProperty("--xsm-theme-text_1_h_2_font_size", "16px");


    // New theme variables text_1_heading_3
    document.body.style.setProperty("--theme-text_1_h_3_color", "#534B4B");
    document.body.style.setProperty("--theme-text_1_h_3_font_size", "12px");
    document.body.style.setProperty("--theme-text_1_h_3_font_style", "normal");
    document.body.style.setProperty("--theme-text_2_h_3_font_weight", "400");
    document.body.style.setProperty("--theme-text_1_h_3_leading", "normal");
    document.body.style.setProperty("--theme-text_1_h_3_font_family", "suisseintl");
    document.body.style.setProperty("--theme-text_1_h_3_letter_spacing", "-0.3px");
    document.body.style.setProperty("--theme-text_1_h_3_font_feature_settings", "'clig' off, 'liga' off'");


    // New theme variables text_2_heading
    document.body.style.setProperty("--theme-text_2_h_color", "#707272");
    document.body.style.setProperty("--theme-text_2_h_font_size", "16px");
    document.body.style.setProperty("--theme-text_2_h_font_style", "normal");
    document.body.style.setProperty("--theme-text_2_h_font_weight", "350");
    document.body.style.setProperty("--theme-text_2_h_leading", "normal");
    document.body.style.setProperty("--theme-text_2_h_font_family", "suisseintl");
    document.body.style.setProperty("--theme-text_2_h_letter_spacing", "-0.3px");
    document.body.style.setProperty("--theme-text_2_h_font_feature_settings", "'clig' off, 'liga' off'");

    document.body.style.setProperty("--text-t2-size", "16px");

    // New theme variables brand_btn_void
    document.body.style.setProperty("--theme-brand_btn_void_color", "rgba(71, 123, 226, 1)");
    document.body.style.setProperty("--theme-brand_btn_void_font_size", "16px");
    document.body.style.setProperty("--theme-brand_btn_void_font_style", "normal");
    document.body.style.setProperty("--theme-brand_btn_void_font_weight", "500");
    document.body.style.setProperty("--theme-brand_btn_void_leading", "normal");
    document.body.style.setProperty("--theme-brand_btn_void_font_family", "suisseintl");
    document.body.style.setProperty("--theme-brand_btn_void_letter_spacing", "-0.3px");
    document.body.style.setProperty("--theme-brand_btn_void_text_center", "center");
    document.body.style.setProperty("--theme-brand_btn_void_font_feature_settings", "'clig' off, 'liga' off'");

    // New theme variables brand_btn_solid
    document.body.style.setProperty("--theme-brand_btn_solid_color", "#FFF");
    document.body.style.setProperty("--theme-brand_btn_solid_bg_color", "#477BE2");
    document.body.style.setProperty("--theme-brand_btn_solid_font_size", "16px");
    document.body.style.setProperty("--theme-brand_btn_solid_font_style", "normal");
    document.body.style.setProperty("--theme-brand_btn_solid_font_weight", "500");
    document.body.style.setProperty("--theme-brand_btn_solid_leading", "normal");
    document.body.style.setProperty("--theme-brand_btn_solid_font_family", "suisseintl");
    document.body.style.setProperty("--theme-brand_btn_solid_letter_spacing", "-0.3px");
    document.body.style.setProperty("--theme-brand_btn_solid_text_center", "center");
    document.body.style.setProperty("--theme-brand_btn_solid_font_feature_settings", "'clig' off, 'liga' off'");
    document.body.style.setProperty("--theme-brand_btn_solid_disabled_bg_color", "#477BE2");
    document.body.style.setProperty("--theme-brand_btn_solid_disabled_color", "#ffffff8c");

    document.body.style.setProperty("--text-t2-color", "#525353");

    // New theme variables body-b1
    document.body.style.setProperty("--text-b1-font_family", "suisseintl-light");
    document.body.style.setProperty("--text-b1-font_weight", "300");
    document.body.style.setProperty("--text-b1-font_size", "26px");
    document.body.style.setProperty("--text-b1-letter_spacing", "-0.3px");
    document.body.style.setProperty("--text-b1-line_height", "normal");

    // New theme variables body-b3
    document.body.style.setProperty("--text-b3-font_family", "suisseintl-light");
    document.body.style.setProperty("--text-b3-font_size", "18px");
    document.body.style.setProperty("--text-b3-font_weight", "300");
    document.body.style.setProperty("--text-b3-letter_spacing", "-0.3px");

    // New theme variables body-b4
    document.body.style.setProperty("--text-b4-font_family", "suisseintl");
    document.body.style.setProperty("--text-b4-font_size", "18px");
    document.body.style.setProperty("--text-b4-font_weight", "500");
    document.body.style.setProperty("--text-b4-letter_spacing", "-0.3px");

    // New theme variables body-b5
    document.body.style.setProperty("--text-b5-font_family", "suisseintl-light");
    document.body.style.setProperty("--text-b5-font_size", "16px");
    document.body.style.setProperty("--text-b5-font_weight", "300");
    document.body.style.setProperty("--text-b5-line_height", "20px");
    document.body.style.setProperty("--text-b5-letter_spacing", "-0.3px");

    // New theme variables body-b7
    document.body.style.setProperty("--text-b7-font_family", "suisseintl-light");
    document.body.style.setProperty("--text-b7-font_size", "13px");
    document.body.style.setProperty("--text-b7-font_style", "normal");
    document.body.style.setProperty("--text-b7-font_weight", "300");
    document.body.style.setProperty("--text-b7-line_height", "normal");
    document.body.style.setProperty("--text-b7-letter_spacing", "-0.3px");
    document.body.style.setProperty("--text-b7-color", "#57595E");

    // New theme variables body-b10
    document.body.style.setProperty("--text-b10-font_family", "suisseintl");
    document.body.style.setProperty("--text-b10-font_size", "13px");
    document.body.style.setProperty("--text-b10-font_weight", "350");
    document.body.style.setProperty("--text-b10-letter_spacing", "-0.3px");

    // New theme variables body-b11
    document.body.style.setProperty("--text-b11-font_family", "suisseintl");
    document.body.style.setProperty("--text-b11-font_size", "10px");
    document.body.style.setProperty("--text-b11-font_weight", "350");
    document.body.style.setProperty("--text-b11-letter_spacing", "-0.3px");
    document.body.style.setProperty("--text-b11-line_height", "normal");
    document.body.style.setProperty("--text-b11-color", "#221F20");

    // New theme variables captions-c1
    document.body.style.setProperty("--text-c1-font_family", "suisseintl");
    document.body.style.setProperty("--text-c1-font_size", "10px");
    document.body.style.setProperty("--text-c1-font_weight", "350");
    document.body.style.setProperty("--text-c1-letter_spacing", "1px");

    // New theme variables captions-c2-standard
    document.body.style.setProperty("--text-c2-std-font_family", "sf-ui-text");
    document.body.style.setProperty("--text-c2-std-font_size", "10px");
    document.body.style.setProperty("--text-c2-std-font_weight", "400");
    document.body.style.setProperty("--text-c2-std-line_height", "11.93px");
    document.body.style.setProperty("--text-c2-std-letter_spacing", "1px");

    // New theme variables captions-c6
    document.body.style.setProperty("--text-c6-font_family", "suisseintl");
    document.body.style.setProperty("--text-c6-font_size", "10px");
    document.body.style.setProperty("--text-c6-font_weight", "500");

    // New theme variables headings-h1
    document.body.style.setProperty("--text-h1-font_family", "din-pro");
    document.body.style.setProperty("--text-h1-font_size", "38px");
    document.body.style.setProperty("--text-h1-font_weight", "400");
    document.body.style.setProperty("--text-h1-line_height", "40px");
    document.body.style.setProperty("--text-h1-letter_spacing", "-0.3px");

    // New theme variables headings-h2
    document.body.style.setProperty("--text-h2-font_family", "din-pro");
    document.body.style.setProperty("--text-h2-font_size", "30px");
    document.body.style.setProperty("--text-h2-font_weight", "400");
    document.body.style.setProperty("--text-h2-line_height", "32px");

    // New theme variables headings-h4
    document.body.style.setProperty("--text-h4-font_family", "din-pro");
    document.body.style.setProperty("--text-h4-font_size", "20px");
    document.body.style.setProperty("--text-h4-font_weight", "400");
    document.body.style.setProperty("--text-h4-letter_spacing", "-0.3px");


    // New theme variables headings-h5
    document.body.style.setProperty("--text-h5-font_family", "din-pro");
    document.body.style.setProperty("--text-h5-font_size", "18px");
    document.body.style.setProperty("--text-h5-font_weight", "400");

    // New theme variables numbers-n1
    document.body.style.setProperty("--text-n1-font_family", "suisseintl-medium");
    document.body.style.setProperty("--text-n1-font_size", "70px");
    document.body.style.setProperty("--text-n1-font_weight", "500");
    document.body.style.setProperty("--text-n1-letter_spacing", "-0.3px");

    // New theme variables numbers-n3
    document.body.style.setProperty("--text-n3-font_family", "din-pro");
    document.body.style.setProperty("--text-n3-font_size", "50px");
    document.body.style.setProperty("--text-n3-font_weight", "400");
    document.body.style.setProperty("--text-n3-letter_spacing", "-0.3px");

    // New theme variables numbers-n6
    document.body.style.setProperty("--text-n6-font_family", "din-pro");
    document.body.style.setProperty("--text-n6-font_size", "16px");
    document.body.style.setProperty("--text-n6-font_weight", "400");
    document.body.style.setProperty("--text-n6-letter_spacing", "-0.3px");

    // New theme variables button-bn1
    document.body.style.setProperty("--text-bn1-font_family", "suisseintl");
    document.body.style.setProperty("--text-bn1-font_size", "20px");
    document.body.style.setProperty("--text-bn1-font_weight", "500");
    document.body.style.setProperty("--text-bn1-letter_spacing", "-0.4px");

    // New theme variables button-bn3
    document.body.style.setProperty("--text-bn3-font_family", "suisseintl");
    document.body.style.setProperty("--text-bn3-font_size", "16px");
    document.body.style.setProperty("--text-bn3-font_weight", "500");

    // New theme variables headline-h1
    document.body.style.setProperty("--text-headline-h1-font_family", "suisseintl");
    document.body.style.setProperty("--text-headline-h1-font_weight", "500");
    document.body.style.setProperty("--text-headline-h1-font_size", "38px");
    document.body.style.setProperty("--text-headline-h1-font_style", "normal");
    document.body.style.setProperty("--text-headline-h1-line_height", "42px");
    document.body.style.setProperty("--text-t4-color", "#fff");
  }
}

export default loadTheme;
