import React from "react";
import {autoPoll} from "../utils/auto_poll";
import {IMAGE_PREFIX} from "../constants/urls.constants";
import emitter from "../utils/emitter";


type TalkingToBossProps = {
  setWorkflow: Function,
  workflow: Object
};
type TalkingToBossState = {intervalId: any};

class TalkingToBoss extends React.Component<TalkingToBossProps, TalkingToBossState> {
  componentDidMount() {
    const interval = autoPoll(this.props.workflow, this.props.setWorkflow);
    const breadcrumbs = {};
    const identifier = this.props.workflow["steps"][0].identifier;
    emitter.emit('progress', { breadcrumbs, identifier });
    this.setState({intervalId: interval});
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  render() {
    const template = this.props.workflow["steps"][0]["ui_template_type"];
    const step = this.props.workflow["steps"][0][template];
    const imgSrc =  `${IMAGE_PREFIX}/${step.image.name}.${step.image.type}`
    const loading = `${IMAGE_PREFIX}/loading.gif`

    return (
      <div>
        <div className="row pb-5">
          <img src={loading} alt="loading" className="m-auto w-20"/>
        </div>
        <div className="text-center row">
          <p className="text-main mb-10" data-testid="title">
            {step.default_state.title}
          </p>
          <img className="w-3/4 mx-auto my-4" src={imgSrc} alt="not found"/>
        </div>
      </div>
    );
  }
}

export default TalkingToBoss;
