import React from 'react';
import ReactDOM from 'react-dom';
import './index-output.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from "@sentry/react";
import loadTheme from './app_theme';
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";
import { v4 as uuidv4 } from 'uuid';
import ClientStorage from './utils/client-storage';
import { DeviceDetailsContext } from './utils/device-details-context';
import parser from 'ua-parser-js';

loadTheme(window["appConfig"].PARTNER_NAME);

Sentry.init({
  dsn: window["appConfig"].SENTRY_DSN,
  initialScope: {
    tags: { "user_id": localStorage.getItem("user_id") }
  },
  integrations: [new CaptureConsoleIntegration(
    {
      // array of methods that should be captured
      // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
      levels: ['error', 'warn']
    }
  )],
  sampleRate: 1.0,
  tracesSampleRate: 1.0,
  environment: window["appConfig"].ENVIRONMENT
});

const {browser, os} = parser();

const setLoginIdentifier = () => {
  const key = 'CUSTOMER_AUTH_LOGIN_IDENTIFIER';
  const identifier = ClientStorage.getLocal(key);
  if(!identifier) {
    ClientStorage.setLocal(key, uuidv4())
  }
}

setLoginIdentifier();

ReactDOM.render(
  <React.StrictMode>
    <DeviceDetailsContext.Provider value={{
      browser: browser.name,
      os: os.name
    }}>
      <App partnerName={window["appConfig"].PARTNER_NAME} />
    </DeviceDetailsContext.Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(null);
