/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { parse } from "query-string";
import { post } from "../utils/client";
import {
  EMAIL_OTP_VERIFY_ENDPOINT_V3,
} from "../constants/urls.constants";

import {
  logErrorToSentry,
  getNewSignInCommonEventProps,
  getSessionId,
  getUSPhoneNumber,
  validatePhoneNumber,
  appSentryCaptureError
} from "../utils/helpers";
import ClientStorage from "../utils/client-storage";
import Loading from "../components/Loading";
import { SignInQueryType } from "../types/SignInQueryType";
import { track } from "../utils/analytics";
import { EVENT } from "../constants/events.constants";
import { AxiosError } from "axios";
import { getStaticErrorActions } from "../utils/phone-signin-dynamic-actions";
import { DESERVE_CARD_OVERVIEW_PATH, ROUTE_BASE_PATH } from "../constants/route-paths.constants";
import jwtDecode from "jwt-decode";

type Props = {
  stepData: any;
  workflowSubmitCb: Function;
};

function SignInVerifyEmailCallback(props: Props) {
  const appConfig = window['appConfig'];
  const location: any = useLocation();
  const history: any = useHistory();
  const isInitialMount = useRef(true);
  const query: SignInQueryType = parse(location.search.replace("?", ""));
  const non_cached_phone_number = ClientStorage.getLocal("non_cached_phone_number") || null;
  const phone_otp_code = ClientStorage.getLocal("phone_otp_code") || null;

  const verifyEmailOTP = useCallback(() => {
    let body = {
      otp: query.otp,
      email: query.email,
      session_id: getSessionId(),
      phone: non_cached_phone_number,
      phone_otp: phone_otp_code,
      tenant_id: appConfig['PROGRAM_ID']
    };

    if (isInitialMount.current) {
      isInitialMount.current = false;
      track(EVENT.dfc_auth_verify_email_otp_from_email_cb_called, {
        ...getNewSignInCommonEventProps(),
      });
    }

    post(EMAIL_OTP_VERIFY_ENDPOINT_V3, body)
      .then((data) => {
        track(EVENT.dfc_auth_verify_email_otp_from_email_cb_ok, {
          ...getNewSignInCommonEventProps(),
        });
        props.workflowSubmitCb({
          ...data,
          phone: props?.stepData?.phone
        });
        if (validatePhoneNumber(props?.stepData?.phone?.replace("+1", "")) && data?.id_token) {
          ClientStorage.setLocal("phone_number", getUSPhoneNumber(props?.stepData?.phone));
        }

        try {
          let account_id = jwtDecode(data?.id_token)['account_id']

          if (window['appConfig'].ENABLE_JWT_TOKEN_PATH_REDIRECTION === 'true' && account_id !== '00000000-0000-0000-0000-000000000000') {
            history.push("/dfc/dashboard");
          } else {
            history.push("/apply-card");
          }
        } catch (e) {
          appSentryCaptureError("verify_email_callback", data, e)
        }

        return data;
      })
      .catch((errResp: AxiosError) => {
        const errorData = errResp?.response?.data;
        const isErrorCode = ["existing_user_email", "expired_otp", "invalid_otp"].includes(errorData?.code);

        logErrorToSentry('signin_verify_email_otp_failed_from_email', body, errResp);
        track(EVENT.dfc_auth_verify_email_otp_from_email_cb_fail, {
          ...getNewSignInCommonEventProps(),
          response: errorData
        });

        if (isErrorCode) {
          props.workflowSubmitCb({
            ...errorData,
            ...getStaticErrorActions(errorData?.code, {
              ...errorData,
              title: errorData?.message
            })
          });
          return null;
        } else {
          history.push("/apply-card/error");
        }
      });
  }, [history, non_cached_phone_number, phone_otp_code, props, query.email, query.otp]);

  useEffect(() => {
    track(EVENT.dfc_auth_verify_email_otp_cb_page_loaded, getNewSignInCommonEventProps());
    if (query.email && query.otp && non_cached_phone_number && phone_otp_code) {
      verifyEmailOTP();
    } else {
      history.push({
        pathname: appConfig.PARTNER_NAME === "deserve" ? DESERVE_CARD_OVERVIEW_PATH : ROUTE_BASE_PATH,
        state: getStaticErrorActions("verify_in_same_browser_error")
      });
      window.location.reload();
    }
  }, [history, non_cached_phone_number, phone_otp_code, props, query.email, query.otp, verifyEmailOTP]);

  return (
    <div className="content-container">
      <Loading />
    </div>
  );
}

export default SignInVerifyEmailCallback;
