import React, { useRef } from 'react';
import { EVENT } from '../constants/events.constants';
import { IMAGE_PREFIX } from "../constants/urls.constants";
import { track } from '../utils/analytics';
import { getNewSignInCommonEventProps } from '../utils/helpers';
import ReactHtmlParser from "html-react-parser";

function SignInError({ stepData }) {
  const appConfig = window['appConfig'];
  const isInitialMount = useRef(true);

  if (isInitialMount.current) {
    isInitialMount.current = false;
    track(EVENT.dfc_auth_error_page_loaded, {
      ...getNewSignInCommonEventProps(),
      ...stepData
    });
  }

  return (
    <div className={`app-container-${appConfig.PARTNER_NAME} signin-flow-page`}>
      <p className="text-main mb-10 text-center text-1-heading" data-testid='title'>{stepData.title}</p>
      {stepData.subtitle && <p className="mb-4 text-sm text-center text-1-heading-2" data-testid='subtitle'>{stepData.subtitle}</p>}
      <img className="w-3/4 mx-auto my-4" src={`${IMAGE_PREFIX}/onbStartPlaceholder.gif`} alt="not found" />
      {stepData?.details?.otp_error_help_text && <p className="mb-4 text-sm text-center text-1-heading-2" data-testid='otp-helper-text'>{ReactHtmlParser(stepData?.details?.otp_error_help_text)}</p>}
      {stepData?.actions && <div className='sm:flex'>
        {
          stepData?.actions?.map((action: any, idx: number) => {
            return (
              <a href={action.redirectTo} key={action.name} data-testid={`button-title-${idx}`} className={`w-72 x-sm:w-auto mt-4 text-center rounded-full text-white block mx-auto focus:outline-none py-3 px-12 x-sm:px-10 ${action.light ? 'consent-button-cancel' : 'bg-theme'}`}>
                {action.title}
              </a>
            );
          })
        }
      </div>}
      {!stepData?.actions && <div className='sm:flex'>
        <a href="/" data-testid='button-try-again' className="w-72 x-sm:w-auto mt-4 text-center rounded-full text-white block mx-auto focus:outline-none py-3 px-12 x-sm:px-10 brand-btn-solid">
          Try Again
        </a>
      </div>}
    </div>
  );
}

export default SignInError;
