import { useState, createContext, useEffect } from "react";
import axios from "axios";
import { DASHBOARD_API_URL } from "../constants/urls.constants";
import { commonHeaders } from "../utils/client";
import { getApplicationToken } from "../utils/helpers";
 
export const AccountDataContext = createContext(null);
export const AccountContextProvider = ({ children }) => {
  const [accountData, setAccountData] = useState({});
  const [accountDataErr, setAccountDataErr] = useState(false);
  const [accountDataErrObj, setAccountDataErrObj] = useState({});

  useEffect(() => {
    const applicationToken =  getApplicationToken();
    const header = { Authorization: `Bearer ${applicationToken}`,  ...commonHeaders() };
    axios
      .get(DASHBOARD_API_URL, { headers: header })
      .then((accountDataRes) => { setAccountData(accountDataRes.data) })
      .catch((err) => {setAccountDataErr(true); setAccountDataErrObj(err)});
  },[]);

  return (
    <AccountDataContext.Provider value={{ accountData, accountDataErr, accountDataErrObj }}>
      {children}
    </AccountDataContext.Provider>
  );
};