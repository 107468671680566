import React from "react";

function BtnNext(props) {
  //TODO load assets based on partner name.
  return (
    <button type={props.btntype ?? 'button'}  {...props}>
      <span className="next-btn-text">
        {props.text ? props.text : getThemeBtn(window["appConfig"].PARTNER_NAME)}
      </span>
    </button>
  );
}

function getThemeBtn(theme_name) {
  const theme_color = getComputedStyle(document.body).getPropertyValue("--theme-color");
  // TODO: use tenant map for "fill" below
  if (theme_name === "earnest") {
    return (
      <svg
        width="62"
        height="62"
        viewBox="0 0 62 62"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ display: "inline-block", cursor: "pointer" }}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31 0C13.8792 0 0 13.8792 0 31C0 48.1208 13.8792 62 31 62C48.1208 62 62 48.1208 62 31C62 13.8792 48.1208 0 31 0ZM25.2929 43.2929L24.5858 44L26 45.4142L26.7071 44.7071L39.7071 31.7071L40.4142 31L39.7071 30.2929L26.7071 17.2929L26 16.5858L24.5858 18L25.2929 18.7071L37.5858 31L25.2929 43.2929Z"
          fill={theme_color}
        />
      </svg>
    );
  } else if (theme_name === "sw") {
    return (
      <svg
        width="62"
        height="62"
        viewBox="0 0 62 62"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ display: "inline-block", cursor: "pointer" }}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31 0C13.8792 0 0 13.8792 0 31C0 48.1208 13.8792 62 31 62C48.1208 62 62 48.1208 62 31C62 13.8792 48.1208 0 31 0ZM25.2929 43.2929L24.5858 44L26 45.4142L26.7071 44.7071L39.7071 31.7071L40.4142 31L39.7071 30.2929L26.7071 17.2929L26 16.5858L24.5858 18L25.2929 18.7071L37.5858 31L25.2929 43.2929Z"
          fill={theme_color}
        />
      </svg>
    );
  } else if (theme_name === "oppfi") {
    return (
      <svg
        className="mx-auto"
        width="65"
        height="62"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28 0C12.536 0 0 12.536 0 28v6c0 15.464 12.536 28 28 28h9c15.464 0 28-12.536 28-28v-6C65 12.536 52.464 0 37 0h-9zm6.429 20.56c.683-.746 1.855-.746 2.587 0l8.447 8.603c.342.349.537.846.537 1.343 0 .498-.195.995-.537 1.343l-8.447 8.604c-.39.348-.83.547-1.318.547-.488 0-.977-.199-1.318-.547-.733-.696-.733-1.89 0-2.636l5.322-5.42H20.855c-1.025 0-1.855-.846-1.855-1.89 0-1.045.83-1.89 1.855-1.89h18.896l-5.322-5.422c-.733-.696-.733-1.89 0-2.636z"
          fill={theme_color}
        />
      </svg>
    );
  }
  else if (theme_name === "krowdfit") {
    return (
      <svg
        width="62"
        height="62"
        viewBox="0 0 62 62"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ display: "inline-block", cursor: "pointer" }}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31 0C13.8792 0 0 13.8792 0 31C0 48.1208 13.8792 62 31 62C48.1208 62 62 48.1208 62 31C62 13.8792 48.1208 0 31 0ZM25.2929 43.2929L24.5858 44L26 45.4142L26.7071 44.7071L39.7071 31.7071L40.4142 31L39.7071 30.2929L26.7071 17.2929L26 16.5858L24.5858 18L25.2929 18.7071L37.5858 31L25.2929 43.2929Z"
          fill={theme_color}
        />
      </svg>
    );
  }

  else if (theme_name === "customers_bank") {
    return (
      <svg
        width="62"
        height="62"
        viewBox="0 0 62 62"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ display: "inline-block", cursor: "pointer" }}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31 0C13.8792 0 0 13.8792 0 31C0 48.1208 13.8792 62 31 62C48.1208 62 62 48.1208 62 31C62 13.8792 48.1208 0 31 0ZM25.2929 43.2929L24.5858 44L26 45.4142L26.7071 44.7071L39.7071 31.7071L40.4142 31L39.7071 30.2929L26.7071 17.2929L26 16.5858L24.5858 18L25.2929 18.7071L37.5858 31L25.2929 43.2929Z"
          fill={theme_color}
        />
      </svg>
    );
  } else if (theme_name === "amc") {
    return (
      <svg
        width="65"
        height="63"
        viewBox="0 0 65 63"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ display: "inline-block", cursor: "pointer" }}
      >
        <rect y="0.5" width="65" height="62" rx="28" fill="#FF2532" />
        <g>
          <path d="M31.0001 21.5L41.0001 31.5M41.0001 31.5L31.0001 41.5M41.0001 31.5H21" stroke="black" strokeWidth="3" fill={theme_color} strokeLinecap="round" strokeLinejoin="round" />
        </g>
      </svg>
    );
  } else if (theme_name === "blockfi") {
    return (
      <svg
        width="65"
        height="63"
        viewBox="0 0 65 63"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ display: "inline-block", cursor: "pointer" }}
      >
        <rect y="0.5" width="65" height="62" rx="28" fill="#FF6230" />
        <g>
          <path d="M31.0001 21.5L41.0001 31.5M41.0001 31.5L31.0001 41.5M41.0001 31.5H21" stroke="white" strokeWidth="3" fill={theme_color} strokeLinecap="round" strokeLinejoin="round" />
        </g>
      </svg>
    );

  } else if (theme_name === 'deserve_edu') {
    return (
      <svg
        width="65"
        height="63"
        viewBox="0 0 65 63"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ display: "inline-block", cursor: "pointer" }}
      >
        <rect y="0.5" width="65" height="62" rx="28" fill="#477BE2" />
        <g>
          <path d="M31.0001 21.5L41.0001 31.5M41.0001 31.5L31.0001 41.5M41.0001 31.5H21" stroke="white" strokeWidth="3" fill="#477BE2" strokeLinecap="round" strokeLinejoin="round" />
        </g>
      </svg>
    );
  } else if (theme_name === "slm_ignite") {
    return (
      <svg
        width="65"
        height="63"
        viewBox="0 0 65 63"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ display: "inline-block", cursor: "pointer" }}
      >
        <rect y="0.5" width="65" height="62" rx="28" fill={theme_color} />
        <g>
          <path d="M31.0001 21.5L41.0001 31.5M41.0001 31.5L31.0001 41.5M41.0001 31.5H21" stroke="white" strokeWidth="3" fill={theme_color} strokeLinecap="round" strokeLinejoin="round" />
        </g>
      </svg>
    );
  } else if (theme_name === "deserve_edu") {
    return (
      <svg
        width="65"
        height="63"
        viewBox="0 0 65 63"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ display: "inline-block", cursor: "pointer" }}
      >
        <rect y="0.5" width="65" height="62" rx="28" fill={theme_color} />
        <g>
          <path d="M31.0001 21.5L41.0001 31.5M41.0001 31.5L31.0001 41.5M41.0001 31.5H21" stroke="white" strokeWidth="3" fill={theme_color} strokeLinecap="round" strokeLinejoin="round" />
        </g>
      </svg>
    );
  }


  return (
    <svg
      width="65"
      height="62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ display: "inline-block", cursor: "pointer" }}
    >
      <rect width="65" height="62" rx="28" fill="#BC9966" />
      <mask
        id="a"
        maskUnits="userSpaceOnUse"
        x="15"
        y="18"
        width="32"
        height="26"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15 18h31.792v25.909H15V18z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#a)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.09 40.93c0-4.536 3.157-7.468 8.044-8.797v-.322H15v-1.715h23.134v-.32c-4.887-1.33-8.044-4.262-8.044-8.797V18h5.81v2.979c0 5.36 5.352 9.297 10.892 9.297v1.311c-5.54 0-10.892 3.936-10.892 9.344v2.978h-5.81v-2.978z"
          fill="#1A1717"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28 0C12.536 0 0 12.536 0 28v6c0 15.464 12.536 28 28 28h9c15.464 0 28-12.536 28-28v-6C65 12.536 52.464 0 37 0h-9zm10.134 32.133c-4.887 1.328-8.044 4.261-8.044 8.797v2.979h5.81v-2.978c0-5.408 5.352-9.344 10.892-9.344v-1.311c-5.54 0-10.892-3.938-10.892-9.297V18h-5.81v2.979c0 4.535 3.157 7.467 8.044 8.797v.32H15v1.715h23.134v.322z"
        fill="#BC9966"
      />
    </svg>
  );
}

export default BtnNext;
