import React, { useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { parse } from "query-string";
import { ROUTE_SINGN_IN_PATH } from '../constants/route-paths.constants';
import { IMAGE_PREFIX } from "../constants/urls.constants";
import { track } from '../utils/analytics';
import { EVENT } from '../constants/events.constants';
import { getNewSignInCommonEventProps } from '../utils/helpers';


function SignInExistingUser({ stepData, captureNameEmailStepData, workflowSubmitCb }) {
  const appConfig = window['appConfig'];
  const location: any = useLocation();
  const isInitialMount = useRef(true);
  const redirected = useRef(false);
  const history: any = useHistory();
  const query = parse(location.search.replace("?", ""));

  if (isInitialMount.current) {
    isInitialMount.current = false;
    track(EVENT.dfc_auth_existing_user_page_loaded, {
      ...getNewSignInCommonEventProps(),
      ...stepData,
      ...captureNameEmailStepData
    });
  }

  let cbData = null;

  if (captureNameEmailStepData) {
    cbData = captureNameEmailStepData;
    redirected.current = false;
  } else if (stepData.code === "existing_user_email") {
    if (!redirected.current) {
      history.push(ROUTE_SINGN_IN_PATH);
    }
    redirected.current = true;
    cbData = {
      next_screen: {
        identifier: "capture_name_email"
      }
    }
  }

  return (
    <div className={`app-container-${appConfig.PARTNER_NAME} signin-flow-page`}>
      <p className="text-main mb-10 text-center text-1-heading" data-testid='title'>{stepData.title}</p>
      {stepData.subtitle && <p className="mb-4 text-sm text-center txt-type-para text-1-heading-2" data-testid='subtitle'>{stepData.subtitle}</p>}
      <img className="w-3/4 mx-auto my-4" src={`${IMAGE_PREFIX}/onbStartPlaceholder.gif`} alt="not found" />
      <div className='flex x-sm:flex-col'>
        <a href="/"
          data-testid="button-title"
          className="w-72 mt-4 text-center rounded-full text-white block mx-auto focus:outline-none py-3 px-12 x-sm:px-10 consent-button-cancel brand-btn-void">
          Exit
        </a>
        <button
          onClick={() => { workflowSubmitCb(cbData) }}
          data-testid="button-title"
          className="w-72 x-sm:w-auto mt-4 text-center rounded-full text-white block mx-auto focus:outline-none py-3 px-12 x-sm:px-10 bg-theme brand-btn-solid">
          Try Another Email
        </button>
      </div>
    </div>
  );
}

export default SignInExistingUser;
