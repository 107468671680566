import Loading from "../../components/Loading";
import { FETCH_FINICITY_INSTITUTIONS_URL, IMAGE_PREFIX, FETCH_FINICITY_LITE_URL } from "../../constants/urls.constants";
import { get } from "../../utils/client";
import { useEffect, useState } from "react";
import {FinicityConnect} from '@finicity/connect-web-sdk';
import { track, trackFailure } from "../../utils/analytics";
import { EVENT } from "../../constants/events.constants";

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay]
  );

  return debouncedValue;
}

const InstitutionsList = (props) => {

    const [loading, setLoading] = useState(false);
    const [institutions, setInstitutions] = useState([]);
    const [search_text, set_search_text] = useState('');
    const debouncedSearchTerm = useDebounce(search_text, 500);

    useEffect(() => {
        const fetch_list = async () => {
            setLoading(true);
            const {default_institutions} = await get(FETCH_FINICITY_INSTITUTIONS_URL);
            setInstitutions(default_institutions);
        }

        fetch_list();
    }, [])

    useEffect(
        () => {
            const fetchInstitutions = async (query) => {
                setLoading(true);
                const filter = query ? `?filter=${query}` : '';
                let api = `${FETCH_FINICITY_INSTITUTIONS_URL}${filter}`;
                let filtered_institutions = [];
                if(query) {
                    const {institutions} = await get(api);
                    filtered_institutions = institutions;
                } else {
                    const {default_institutions} = await get(api);
                    filtered_institutions = default_institutions;
                }
                setInstitutions(filtered_institutions);
                setLoading(false);            
            }
            fetchInstitutions(debouncedSearchTerm);
        },
        [debouncedSearchTerm]
      );
    
    if(loading) {
        return <Loading />
    }

    const getFinicityConnectLiteURL = async (institution_id) => {
        const {connect_url, customer_id} = await get(FETCH_FINICITY_LITE_URL(institution_id));


        const connectEventHandlers = {
            onDone: (e) => {
                track(EVENT.finicity_add_account_success);
            },
            onCancel: (e) => {

            },
            onError: ({code, reason}) => {
                trackFailure(EVENT.finicity_add_account_failed, {
                    response: {
                        data: {
                            code
                        },
                    },
                    message: reason
                })
            },
            onUser: ({ action, code, customerId }: any) => {
                
            },
            onLoad: () => {
                console.log('loaded');
            }
        }

        const connectOptions = {
            overlay: 'rgba(199,201,199, 0.5);z-index: 10000;'
        }

        FinicityConnect.launch(
            connect_url,
            connectEventHandlers,
            connectOptions
        )
    }

    return (
        <>        
        <div className="flex flex-col w-full self-center absolute top-0 left-0 bottom-0 bg-white opacity-80 z-40 p-10">
        </div>
        <div className="absolute z-50 w-full flex flex-col left-0">
            <div className="self-center w-1/2 flex flex-col gap-4">
                <div className="flex justify-between">
                <div className="py-4 text-black">
                    Please select your bank to continue
                </div>
                <div
                    onClick={props.onClose}
                    title="Back to Dashboard"
                    className="w-8 cursor-pointer rounded-full  page-close"
                >
                    <img src={`${IMAGE_PREFIX}/close-page.png`} alt="Cancel" />
                </div>
                </div>
                <div>
                    <input data-testid="search-institutions-input" value={search_text} onChange={(e) => {
                        set_search_text(e.target.value)
                    }} type="text" placeholder="Type your bank to search" className="rounded border border-[#ececec] p-2 w-full placeholder-gray-200 text-black" />
                </div>
                <div className="border border-[#ececec] rounded bg-white opacity-100 w-full">
                    <ul>
                        {
                            institutions.map((institution) => {
                                return (
                                    <li key={institution.id} onClick={() => {
                                        getFinicityConnectLiteURL(institution.id)
                                    }} className="p-4 text-black flex gap-2 items-center hover:bg-gray-300 cursor-pointer" data-testid={`${institution.name}`}>
                                        <img className="w-10 h-10" src={`${institution.branding?.icon}`} alt={`Logo of ${institution.name}`} />
                                        {
                                            institution.name
                                        }
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
        </div>
        </>
    )
}

export default InstitutionsList;