import React, { useEffect } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import Toast from '../../components/Toast';
import { IMAGE_PREFIX } from "../../constants/urls.constants";
import { clearHomeRouteState, getCachedPhoneNumber, isNewSignInFlowEnabled, trackUTM } from "../../utils/helpers";
import { track } from "../../utils/analytics";
import { NewSignInFlowActions } from '../../components/NewSignInFlowActions';
import { OldSignInFlowActions } from '../../components/OldSignInFlowActions';
import { AutoHideToast } from '../../components/AutohideToast';

function Oppfi() {
  const location: any = useLocation();
  const history: any = useHistory();
  const { title } = location.state || {};
  const params = new URLSearchParams(location.search);
  const message = params.get("message");
  const phoneNumber = getCachedPhoneNumber();

  track('page_loaded', {url: window.location.href});
  trackUTM('marketingData', window.location.href);

  useEffect(() => {
    clearHomeRouteState(title, history);
  }, []);

  return (
    <>
      {title && <AutoHideToast title={title} timeout={6000} />}
      <div className="oppfi-container bg-oppfi w-full min-h-screen x-sm:min-h-full px-20 x-sm:px-8 py-7 x-sm:pb-16 font-arimo">
        {message && <Toast cssClasses="w-1/3 x-sm:w-full m-auto absolute top-6 x-sm:top-2 left-0 right-0 z-50 bg-white x-sm:p-4 text-center" text={message} timeout={6000} />}
        <div className="flex justify-between mb-14 x-sm:mb-0 mt-6 x-sm:mt-0 x-sm:items-center x-sm:w-1/2 w-60">
          <a className="inline-block" href="/"><img data-testid="index-logo" src={`${IMAGE_PREFIX}/logo.svg`} alt="logo" /></a>
        </div>
        <div>
          <div className="oppfi-wrap min-h-screen pb-8 x-sm:text-center flex flex-col md:justify-center">
            <div className="bg-oppfi-mobile"></div>
            <h1 className="oppfi-heading xl:mb-8 x-sm:mb-0 text-6xl x-sm:text-3xl font-bold" data-test-id="home-page-heading">Opportunity <br className="x-sm:hidden" /> for More.</h1>
            <p className="theme-text2-color x-sm:text-sm text-xl mt-4 x-sm:mb-2 mb-2">
              A credit card that gives you more so <br />you can worry less.*
            </p>
            <div className="x-sm:w-full x-sm:h-0.5 x-sm:mt-2 x-sm:mb-1 x-sm:bg-gray-200">
            </div>
            <div className="lg:hidden 2xl:hidden xl:hidden md:hidden x-sm:flex x-sm:justify-center x-sm:py-2" data-test-id="home-page-subheading">
              <span>Sign in or Apply below</span>
            </div>
            {isNewSignInFlowEnabled() ? <NewSignInFlowActions /> : <OldSignInFlowActions />}
          </div>
          <div className="approval-text x-sm:pr-3 x-sm:bottom-10 x-sm:mt-8 x-sm:relative">
            <p className="theme-text2-color x-sm:pr-3.5 text-xxs x-sm:relative" data-testid="t_and_c">*Subject to credit approval, verification and required download of the OppFi mobile app (available on iOS and Android mobile devices). Issued by First Electronic Bank, Member FDIC.</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Oppfi;
